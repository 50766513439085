import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Andriod from '../assets/Android.png'
import Ios from '../assets/Ios.png'
import HowIt from './HowIt';
function GoverenmentOfficer() {
  return (
    <section>
    <div className='bg-white'>
      <h1 className='text-3xl font-bold text-center pt-6 pb-2'>
      CORPORATE SECURITY APP
      </h1>
      <p className='text-center text-gray-500 pb-5 p-10'>
      At AVES Security Management App, we present an advanced corporate security app that will help you secure your organization, business, people as well as data. Rich in features and functionality, yet easy to use, AVES offers a completely new way for companies to look at the problem of security.
      </p>
      <div className="bg-white p-6 md:p-12">
      {/* Header Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
        WHY SHOULD SOMEONE DOWNLOAD OUR CORPORATE SECURITY APP?
      </h2>

      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Andriod}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-start gap-3">
              <span className="text-green-600 text-xl">✔</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="bg-white p-6 md:p-12">
      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Features Section */}
        <div className="space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-start gap-3">
              <span className="text-green-600 text-xl">✔</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Ios}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
    </div>
    <HowIt/>
    <div>
    <div className="flex justify-center text-left pb-5">
          <Step number="3" title="Access Security Features" description="View reports, checklists, and real-time alerts instantly." />
        </div>
      <h1 className='text-3xl font-bold text-center pt-6 pb-10 p-5'>
      Experience the best mobile app for security with Aves – a state of the art Security Management App for your corporate world
      </h1>
    </div>
    <Form/>
    </section>
  )
}
const features = [
  {
    title: "COMPREHENSIVE SECURITY SOLUTIONS",
    description: "You get an all-in-one security solution for your corporate security needs from AVEs."
  },
  {
    title: "REAL-TIME MONITORING",
    description: "Watch out for instant alerts and get real-time information you need at your fingertips."
  },
  {
    title: "CUSTOMIZABLE OPTIONS",
    description: "The app can and should be tailored to the needs and demands in your specific organization/business."
  },
  {
    title: "DATA-DRIVEN DECISIONS",
    description: "Get analysis and information that you need for effective and improved security standards."
  },
  {
    title: "EASE OF USE",
    description: "This results in the concept of intuitive design since its adoption and implementation does not take time."
  }
];

const Step = ({ number, title, description }) => {
  return (
    <div className="flex  items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
    
  )
}

export default GoverenmentOfficer