import { Link } from 'react-router-dom';


const Testimonial = () => {
  return (
    <section id='testimonials'>
      {/* Container to heading and testm blocks */}
      <div className='max-w-6xl px-5 mx-auto mt-32 text-center'>
        {/* Heading */}
        <h2 className='text-4xl font-bold text-center'>
        Features Designed for Superior Security Management
        </h2>
        {/* Testimonials Container */}
        <div className='flex flex-col mt-24 md:flex-row md:space-x-6'>
          {/* Testimonial 1 */}
          <div className='flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3'>
            <h5 className='text-lg font-bold'>Guard Tour Tracking</h5>
            <p className='text-sm text-darkGrayishBlue'>
            Monitor patrols with GPS-enabled tracking for enhanced accountability.</p>

          </div>

          {/* Testimonial 2 */}
          <div className='hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3'>
            <h5 className='text-lg font-bold'>Incident Management</h5>
            <p className='text-sm text-darkGrayishBlue'>
            Record, manage, and resolve incidents swiftly and efficiently.
            </p>
          </div>

          {/* Testimonial 3 */}
          <div className='hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3'>
            <h5 className='text-lg font-bold'>Time & Attendance Tracking</h5>
            <p className='text-sm text-darkGrayishBlue'>
            Simplify workforce management with automated scheduling and attendance features.
            </p>
          </div>
        </div>
        {/* Button */}
        <div className='my-16'>
          <Link
            to='#'
            className='p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight'
          >
            Get Started
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
