import React from "react";
import Aves from '../assets/AvesAndriod.png'

const HowIt = () => {
  return (
    <div className="bg-white p-6 md:p-12 text-center">
      {/* Title */}
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-8">
        How It Works
      </h2>

      {/* Layout */}
      <div className="relative grid md:grid-cols-3 gap-8 items-center">
        {/* Left Steps */}
        <div className="space-y-8 text-left">
          <Step number="1" title="Download the App" description="For iPhone and iPad users and also for Android users." />
        </div>

        {/* Center Image */}
        <div className="flex justify-center">
          <img
            src={Aves}
            alt="Mobile UI"
            className="w-full max-w-xs md:max-w-sm mx-auto rounded-lg shadow-lg"
          />
        </div>
        

        {/* Right Steps */}
        <div className="space-y-8 text-left">
          <Step number="3" title="Access Security Features" description="View reports, checklists, and real-time alerts instantly." />
        </div>
      </div>
    </div>
  );
};

const Step = ({ number, title, description }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  );
};

export default HowIt;
