import React, { useState, useEffect } from 'react';
import { Check, X } from 'lucide-react';
import axios from 'axios';

const Plans = () => {
  const [activeTab, setActiveTab] = useState('standard');
  const [isYearly, setIsYearly] = useState(true);
  const [plans, setPlans] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [userDetails, setUserDetails] = useState({ plan_order: 0 });
  const [sessionStore, setSessionStore] = useState({ package_name: '', authURL: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetching data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('http://localhost:3000/userapi/allplans');
        const data = response.data;

        // Set the fetched plans and plan options
        setPlans(data.plandata);
        setPlanOptions(data.planoptions);

        // Mock user details and session (you can replace this with real data)
        setUserDetails({ plan_order: 2 }); // Mock user on "Small store" plan
        setSessionStore({ package_name: 'small_store', authURL: '/auth' });

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter plans based on the selected tab (e.g., 'standard', 'customized')
  const filteredPlans = plans.filter(plan => plan.plan_type === '1'); // Filter for standard plans only

  // Handle loading and error states
  if (loading) return <div className="text-center py-20">Loading plans...</div>;
  if (error) return <div className="text-center py-20 text-red-500">Error: {error}</div>;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl lg:text-5xl">
          Flexible Plans for Every Organization
          </h1>
          <p className="mt-4 text-xl text-gray-600">
          Choose between Standard and Premium plans to best suit your needs.


          </p>
        </div>

        {/* Tabs */}
        <div className="mt-8 flex justify-center">
          <button
            className={`px-6 py-3 rounded-l-lg text-lg font-semibold transition-colors ${activeTab === 'standard' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
            onClick={() => setActiveTab('standard')}
            style={{ height: '56px' }}
          >
            Standard
          </button>
          <button
            className={`px-6 py-3 rounded-r-lg text-lg font-semibold transition-colors ${activeTab === 'customized' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
            onClick={() => setActiveTab('customized')}
            style={{ height: '56px' }}
          >
            Customized
          </button>
        </div>

        {activeTab === 'standard' && (
          <>
            {/* Billing Toggle */}
            <div className="mt-8 flex justify-center">
              <button
                className={`px-6 py-2 rounded-l-lg text-lg font-medium transition-colors ${isYearly ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
                onClick={() => setIsYearly(true)}
              >
                Yearly
              </button>
              <button
                className={`px-6 py-2 rounded-r-lg text-lg font-medium transition-colors ${!isYearly ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
                onClick={() => setIsYearly(false)}
              >
                Monthly
              </button>
            </div>

            {/* Plan Cards */}
            <div className="mt-16 grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              {filteredPlans.map((plan) => (
                <div key={plan.id} className="relative rounded-2xl bg-white p-8 shadow-lg">
                  <div className="mb-8">
                    <h2 className="text-2xl font-bold text-gray-900">{plan.plan_name}</h2>
                    <p className="mt-2 text-gray-500" dangerouslySetInnerHTML={{ __html: plan.plan_desc }}></p>
                    <div className="mt-4">
                      <span className="text-4xl font-bold text-gray-900">
                        {isYearly ? (
                          plan.yearly_price_sale === '0' ? (
                            `$${plan.yearly_price}`
                          ) : (
                            <>
                              <del>${plan.yearly_price}</del> ${plan.yearly_price_sale}
                            </>
                          )
                        ) : (
                          plan.monthly_price_sale === '0' ? (
                            `$${plan.monthly_price}`
                          ) : (
                            <>
                              <del>${plan.monthly_price}</del> ${plan.monthly_price_sale}
                            </>
                          )
                        )}
                      </span>
                      <span className="text-gray-500"> / {isYearly ? 'year' : 'month'}</span>
                    </div>
                  </div>

                  {/* Plan Features */}
                  <ul className="mb-8 space-y-4">
                    {planOptions.map((option) => {
                      const isIncluded = option.f_value.split('|')[plan.plan_order] === 'Yes'; // Check if option applies to current plan
                      return (
                        <li key={option.f_id} className="flex items-center gap-3 text-gray-700">
                          {isIncluded ? (
                            <Check className="h-5 w-5 text-green-500" />
                          ) : (
                            <X className="h-5 w-5 text-red-500 " />
                          )}
                          {option.f_name}
                        </li>
                      );
                    })}
                  </ul>

                  {/* Button for Current Plan */}
                  {sessionStore.package_name === plan.plan_slug ? (
                    <a
                      className="w-full block text-center rounded-lg border-2 py-3 font-semibold border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white transition-colors"
                      href="/profile"
                    >
                      Current Plan
                    </a>
                  ) : plan.plan_order > userDetails.plan_order ? (
                    <a
                      className="w-full block text-center rounded-lg border-2 py-3 font-semibold border-green-600 text-green-600 hover:bg-green-600 hover:text-white transition-colors"
                      href={`/upgrade/${plan.plan_slug}${isYearly ? '/yearly' : ''}`}
                    >
                      Get Started
                    </a>
                  ) : (
                    <a
                      className="w-full block text-center rounded-lg border-2 py-3 font-semibold border-gray-400 text-gray-400 cursor-not-allowed"
                      href="/profile"
                      title="You Can't Downgrade your Plan"
                    >
                      Disabled
                    </a>
                  )}
                </div>
              ))}
            </div>
          </>
        )}

        {activeTab === 'customized' && (
          <div className="mt-16 text-center">
            <p className="mt-5 text-center mb-4 text-lg text-gray-600">
              For a fully owned customized solution (No SaaS). Send us a message, and we will get back to you as soon as possible to create a website without limits.
            </p>
            <a
              className="inline-block px-8 py-4 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors"
              href="https://wcart.io/#contact"
            >
              Enquire Now
            </a>
          </div>
        )}

        <div className="mt-12 text-center">
          <p className="text-gray-600">
            All plans include a {plans[0]?.trial_period_days || 14}-day free trial. No credit card required.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Plans;
