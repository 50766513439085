import { Link } from 'react-router-dom';

import illustrationIntro from '../assets/images/Security.jpeg';
import office from '../assets/images/Office.png'

const Hero = () => {
  return (
<section id="hero">
  <div className="bg-white">
    <div className="flex flex-col lg:flex-row items-center lg:items-center justify-center lg:justify-center">
      <div className="flex justify-center lg:justify-start lg:w-1/2 p-5 rounded-3xl lg:p-10 xl:p-8 ">
        <img
          src={illustrationIntro}
          alt="Security Illustration"
          className="lg:max-w-full lg:h-[650px]"
        />
      </div>
      <div className="flex justify-center lg:justify-center lg:w-1/2 p-6">
        <div>
          <h1 className="text-3xl font-bold text-center text-sky-900 pt-9 p-4 md:text-5xl md:pt-10 lg:text-left lg:pt-0 xl:text-left xl:pt-0">
            Aves Security Management System
          </h1>
          <p className="text-lg text-center lg:text-left">
            At Aves Security Management System, we specialize in providing cutting-edge security management solutions designed to empower your business. From comprehensive guard management to seamless incident tracking, our platform ensures your operations run efficiently and securely.
          </p>
          <div className="lg:w-96 h-96">
            <img className="" src={office} alt="" />
            <span className="flex justify-center items-center text-5xl font-bold text-sky-900 p-4 relative -top-7">
              <p>50K+</p>
            </span>
            <Link
        to="#"
        className="p-3 px-6 pt-2 text-white font-bold rounded-full shadow-3xl flex justify-center items-center max-w-xl max-h-xl  bg-blue-700 hover:bg-blue-800 text-lg md:text-xl lg:text-2xl"
      >
        Learn More
      </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default Hero;
