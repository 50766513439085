import React from "react";
import video from '../assets/Video.png';
import playstore from '../assets/Download Store.png';
import download from '../assets/Download.png';
import Form from "./Form";
import Akar from '../assets/Akar.png';
import Alarm from '../assets/Alarm.png';
import frame from '../assets/Frame.png';
import New from '../assets/new.png';
import Old from '../assets/Old.png';
import Heart from '../assets/Heart.png';
import layer from '../assets/Layer_1.png';
import Line from '../assets/Line.png';
import Desk from '../assets/Desk.png';
import Hand from '../assets/Hand.png';
import Cash from '../assets/cash.png';
import Book from '../assets/Book.png';
import Img from '../assets/Img.png';
import Like from '../assets/Like.png';
import Export from '../assets/Export.png';

const Features = () => {
  const cards = [
    {
      title: "Streamlined Guard Scheduling",
      description: "AVES Security Management brings everything you need into one app. Whether it’s managing teams, tracking incidents, or ensuring compliance.",
      icon: Akar,
    },
    {
      title: "Real-Time Tracking and Reporting",
      description: "Keep track of incidents and reports in real-time. AVES Security Management ensures you never miss a thing.",
      icon: Alarm,
    },
    {
      title: "Easy-to-Use Mobile and Desktop Interfaces",
      description: "Our platform works seamlessly across devices, making it accessible and efficient for all users.",
      icon: frame,
    },
    {
      title: "User-Friendly Interface",
      description: "Navigate effortlessly through our intuitive and straightforward interface.",
      icon: New,
    },
    {
      title: "Enhanced Accountability",
      description: "Monitor team performance and ensure compliance with automated workflows.",
      icon: Old,
    },
    {
      title: "Customizable For Your Specific Needs",
      description: "AVES adapts to businesses of all sizes, tailored to meet your specific requirements.",
      icon: Heart,
    },
  ];

  const system = [
    {
      title: "Real-Time Monitoring & Reporting",
      description: "Stay connected with your team 24/7. Our system provides real-time updates, detailed reports, and actionable data for better decision-making.",
      icon: layer,
    },
    {
      title: "Streamlined Guard Scheduling",
      description: "From incident reporting to real-time tracking, AVES equips your team with cutting-edge tools to handle every aspect of security management.",
      icon: Line,
    },
    {
      title: "Easy-to-Use Mobile and Desktop Interfaces",
      description: "AVES adapts to businesses of all sizes. Whether you manage a small team or a global operation, we tailor our solutions to meet your specific requirements.",
      icon: Desk,
    },
    {
      title: "Enhanced Accountability",
      description: "Track team performance, reduce errors, and ensure compliance with automated workflows and digital logs.",
      icon: Hand,
    },
    {
      title: "User-Friendly Interface",
      description: "Our platform is designed for simplicity, making it easy for your team to navigate and use effectively, even with minimal training.",
      icon: Cash,
    },
    {
      title: "Customizable For Your Specific Needs",
      description: "AVES adapts to businesses of all sizes, tailored to meet your specific requirements.",
      icon: Akar,
    },
  ];

  const features = [
    {
      title: "Easy Management",
      description: "Handle everything in one place—staff schedules, reports, and tasks—all connected and simple to use.",
      icon: Book,
    },
    {
      title: "Simple Design",
      description: "Our app is easy to understand and use, making your work faster and stress-free.",
      icon: Img,
    },
    {
      title: "Real-Time Updates",
      description: "Get live updates and quick reports to make better decisions without waiting.",
      icon: Like,
    },
    {
      title: "Safe and Secure",
      description: "Protect your data and workflow with advanced security measures.",
      icon: Export,
    },
  ];

  return (
    <div className="px-4 py-8 md:py-12 lg:py-16 bg-white">
      {/* Why Choose Us Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">
        Why Choose Us?
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {cards.map((card, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300"
          >
            <img src={card.icon} alt={card.title} className="w-16 h-16 mb-4 object-contain" />
            <h3 className="text-lg font-semibold mb-2 text-center">
              {card.title}
            </h3>
            <p className="text-gray-600 text-center">{card.description}</p>
          </div>
        ))}
      </div>

      {/* Aves Security Management Section */}
      <div>
        <h1 className="mt-10 flex justify-center items-center text-3xl font-bold text-center text-gray-800 pb-5">
          Aves Security Management System
        </h1>
        <p className="text-center text-gray-600 mb-5 font-medium">
          Make your security management easier today. Go paperless with AVES
          Security Management. Sort, filter, and create reports with just a
          click!
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {system.map((sys, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300"
            >
              <img src={sys.icon} alt={sys.title} className="w-16 h-16 mb-4 object-contain" />
              <h3 className="text-lg font-semibold mb-2 text-center">
                {sys.title}
              </h3>
              <p className="text-gray-600 text-center">{sys.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <h3 className="text-2xl font-bold text-center mb-12">
            OUR FEATURES
          </h3>
          <p className="pb-10 text-center text-gray-600 mb-5 font-medium">
            Designed for Superior Security Management. Make your security management easier today. Go Paperless with 
            AVES Security Management. Sort, filter, and create reports with just a click!
          </p>
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg text-center shadow-md"
              >
                <img src={feature.icon} alt={feature.title} className="w-16 h-16 mb-4 object-contain flex items-center justify-center mx-auto" />
                <h4 className="text-lg font-semibold mt-4">{feature.title}</h4>
                <p className="text-sm mt-2">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Black Section */}
      <div className="bg-[#2E2D2D] text-white py-32">
        <h1 className="text-4xl text-center mt-0 pb-16 font-bold">
          Streamline Your Security Operations Today
        </h1>
        <p className="font-semibold text-center text-slate-300 mb-5 text-base">
          Take the first step towards a safer and more efficient future. Aves
          Security Management System is your trusted partner in security
          management, offering innovative solutions that empower your team and
          secure your assets.
        </p>
      </div>

      {/* Video Image Section */}
      <div className="flex justify-center items-center py-10">
        <img
          className="w-[770px] h-[319px] relative bottom-52"
          src={video}
          alt="Aves Security Video"
        />
      </div>

      <div className="p-10 relative bottom-64">
        <h1 className="text-3xl font-bold text-[#232233] pb-2">
          Download App Now
        </h1>
        <p className="text-[#6C6C72] text-base font-medium">
          Whether you’re managing a small team or a large-scale operation,<br />
          AVES Security Management offers plans tailored to fit your needs.<br />
          Each plan is designed to provide the tools and features necessary <br />
          to streamline your security processes while maintaining cost-effectiveness.
        </p>
        <img
          className="pt-5"
          src={playstore}
          alt="Download from Playstore"
        />
        <span className="flex flex-row gap-10 mt-10 pl-[-15px]">
          <img className="" src={download} alt="Download Icon" />
          <img className="" src={download} alt="Download Icon" />
          <img className="" src={download} alt="Download Icon" />
        </span>
      </div>

      {/* Form Section */}
      <Form />
    </div>
  );
};

export default Features;
