import React, { useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Homes from './components/Homes';
import About from './components/About';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Service from './components/Service';
import Plans from './components/Plans';
import Securitypass from './components/Securitypass';
import CorprateSecurity from './components/CorprateSecurity';
import ResidentialComplex from './components/ResidentialComplex';
import HealthCareSecurity from './components/HealthCareSecurity';
import GoverenmentOfficer from './components/GoverenmentOfficer';
import EducationApp from './components/EducationApp';
import CampousSecurity from './components/CampousSecurity';
import EventSecurity from './components/EventSecurity';
import TearmsAndCondition from './components/TearmsAndCondition';
import EmailSubmit from './EmailSubmit';
import Dashboard from './Dashboard';
import Qrcode from './Qrcode';
import Hospitality from './components/Hospitality';
import AuxilaryPolice from './components/AuxilaryPolice';
import Form from './components/Form';

function App() {
  const location = useLocation();
  const formRef = useRef(null);

  const noHeaderFooter = location.pathname === '/Qrcode' || location.pathname === '/Email-submit';

  return (
    <>
      {!noHeaderFooter && <Navbar formRef={formRef} />}
      <Routes>
        <Route path="/" element={<Homes />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/Hospitality" element={<Hospitality />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/pass" element={<Securitypass />} />
        <Route path="/Corporate" element={<CorprateSecurity />} />
        <Route path="/Residential" element={<ResidentialComplex />} />
        <Route path="/Healthcare" element={<HealthCareSecurity />} />
        <Route path="/Government" element={<GoverenmentOfficer />} />
        <Route path="/Education" element={<EducationApp />} />
        <Route path="/Manufacturing" element={<CampousSecurity />} />
        <Route path="/Event" element={<EventSecurity />} />
        <Route path="/AuxiliaryPolice" element={<AuxilaryPolice />} />
        <Route path="/Terms" element={<TearmsAndCondition />} />
        <Route path="/Qrcode" element={<Qrcode />} />
        <Route path="/Email-submit" element={<EmailSubmit />} />
        <Route path="/Dashboard" element={<Dashboard />} />
      </Routes>
      <Form ref={formRef} />
      {!noHeaderFooter && <Footer formRef={formRef} />}
    </>
  );
}

export default App;
