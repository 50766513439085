import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Andriod from '../assets/Campous Security.png'
import Ios from '../assets/CampusOfficers.png'
import HowIt from './HowIt';
import { Check } from 'lucide-react';
import SecurityMale from '../assets/CampousMain.png'
import HospitaltyBanner from '../assets/CompousBanner.png'
import Restarant from '../assets/Restatrant.png'
import Event from '../assets/Eventicon.png'
import Resort from '../assets/Resort.png'
import ConventionCenter from '../assets/ConventionCentre.png'
import Tour from '../assets/Tourisim.png'

function CampousSecurity() {
  return (
    <section>
       <div className='bg-white'>
         <div>
           <img src={SecurityMale} alt="Security App" className="w-screen" />
           <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        MANUFACTURING

  </h1>
  <h3 className='font-light'>
  SECURITY GOVERNANCE SYSTEM
  </h3>
        </span>
         </div>
         <div className='bg-[#F7F7F7]'>
   
         <div className="text-center p-5 text-gray-500 font-semibold space-y-4 max-w-2xl mx-auto leading-relaxed pt-10">
  <p>
    AVES is a security governance system designed to electronically manage security activities 
    for manufacturing plants, warehouses, and industrial locations.
  </p>
  <p>
    It provides authoritative control over visitor tracking, workforce security, asset protection, 
    and emergency response administration.
  </p>
</div>

         <div className="bg-[#F7F7F7] p-6 md:p-12">
         {/* Header Section */}
         <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
         WHY ARE MANUFACTURING PLANTS BEING ENCOURAGED TO IMPLEMENT AVES SECURITY GOVERNANCE SYSTEM?
         </h2>
         </div>
   
         {/* Content Section */}
         <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Andriod}
               alt="Security App"
               className="w-full max-w-lg rounded-3xl shadow-xl"
             />
           </div>
   
           {/* Features Section */}
           <div className="space-y-6">
             {features.map((feature, index) => (
               <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                          <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
                     <Check className='w-4 h-4 relative bottom-[8px] right-[8px] text-center' />
                 </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>
         </div>
       </div>
       <div className="bg-white p-6 md:p-12">
       <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
       MAJOR CHARACTERISTICS OF OUR AVES EVENT SECURITY SOFTWARE
         </h2>
         {/* Content Section */}
         <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Features Section */}
           <div className="space-y-6">
             {feature.map((feature, index) => (
               <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
                             <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
                        <Check className='w-4 h-4 relative bottom-[8px] right-[8px] text-center' />
                    </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>
   
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Ios}
               alt="Security App"
               className="w-full max-w-md rounded-lg shadow-lg"
             />
           </div>
         </div>
       </div>
       </div>
       <div>
       </div>
       <div className="relative w-full">
         {/* Background Image */}
         <img
           src={HospitaltyBanner}
           alt="Hospitality Security"
           className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
         />
   
         {/* Text Overlay */}
         <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
           <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
           Get Started Today  
           </h2>
           <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0 pt-3">
           Experience the best mobile app for security with Aves – a state of the art Security  Governance System for your corporate world
           </p>
   
           {/* CTA Button */}
           <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
             Contact Us →
           </button>
         </div>
       </div>
       <div className="px-6 lg:px-20 py-10 bg-gray-100">
         {/* Title Section */}
         <div className="text-center mb-10">
           <h2 className="text-2xl font-bold uppercase text-gray-800">
             FOR WHOM WE ARE BUILDING
           </h2>
           <h3 className="text-2xl font-bold text-gray-800">
             OUR MANUFACTURING SECURITY SYSTEM
           </h3>
         </div>
         
           {/* Icon Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">FACTORIES & INDUSTRIAL FACILITIES</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">WAREHOUSES & DISTRIBUTION CENTERS</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">HEAVY MACHINERY & EQUIPMENT PLANTS</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">MANUFACTURING UNITS</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">AUTO & ELECTRONICS MANUFACTURING UNITS</p>
  </div>
</div>
   {/* How It Works Section */}   
       </div>
        <div className='bg-white '>
                 <div className="mt-16 text-center px-10 py-10 rounded-xl">
                   <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
                 
                   <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                  {/* Step 1 */}
                  <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
                    <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
                      1
                    </div>
                    <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
                    <p className="mt-2 text-gray-600 text-sm">
                    Sign up to keep security operations online within your manufacturing facility.
                    </p>
                  </div>
              
                  {/* Step 2 */}
                  <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
                    <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
                      2
                    </div>
                    <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
                    <p className="mt-2 text-gray-600 text-sm">

                    Design visitor tracking, employee safety protocols, and equipment security management.

                    </p>
                  </div>
              
                  {/* Step 3 */}
                  <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
                    <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
                      3
                    </div>
                    <h3 className="mt-4 text-lg font-bold text-gray-700">Start Governing Manufacturing Security </h3>
                    <p className="mt-2 text-gray-600 text-sm">
                    Keep systematic security management on access management, incident reporting, and compliance tracking.
                    </p>
                  </div>
                </div>
                 </div>
                 </div>
    
       </section>
     )
   }
   const features = [
     {
       title: "COMPREHENSIVE SECURITY SOLUTIONS FOR MANUFACTURING PLANTS",
       description: "AVES offers a complete Manufacturing Plant Security solution aimed at optimizing and improving your organization's security processes"
     },
     {
       title: "REAL-TIME SURVEILLANCE",
       description: "Maintain an edge with immediate notifications and real-time information readily available, facilitating proactive security oversight in manufacturing facilities"
     },
     {
       title: "CUSTOMIZABLE AND SCALABLE",
       description: "The Security Management Platform for Manufacturing Plants is adaptable to fulfill the specific requirements of your organization, offering a flexible and scalable security structure."
     },
     {
       title: "USER-FRIENDLY AND INTUITIVE",
       description: "Our Manufacturing Security Guards Management Platform is crafted for easy integration, minimizing implementation time through a user-friendly interface."
     },
     {
       title: "DATA-DRIVEN SECURITY STRATEGIES",
       description: "Utilize actionable insights and analytics to enhance security protocols throughout your manufacturing plant."
     }
   ];
   
   const feature = [
     {
       title: "WORKFORCE SAFETY & INCIDENT TRACKING",
       description: "Track workplace injuries, safety incidents, and emergency events.Maintain security breach records and compliance actions in an organized manner.",
     },
     {
       title: "CONTRACTOR & SUPPLIER ACCESS GOVERNANCE",
       description: "Manage entry permits for contractors, suppliers, and external vendors.Maintain electronic records of temporary access passes and clearance approvals"
     },
     {
       title: "ASSET PROTECTION & EQUIPMENT TRACKING",
       description: "Track movement of high-value equipment and track essential inventory. Maintain orderly records of lost items, tools in need of repair, and maintenance history."
     },
     {
       title: "FACTORY ACCESS & SECURITY CHECKPOINT MANAGEMENT",
       description: "Track employees gate passes, and access authorizations record.Ensure secure transit through restricted zones such as manufacturing sections and warehouses"
     },
     {
       title: "EMPLOYEE & WORKFORCE SAFETY TRACKING",
       description: "Track workplace safety concerns, accident reports, and emergency alerts.Keep systematic records of lost items, security breaches, and resolution of incidents"
     }
   ]
   
   const Step = ({ number, title, description }) => {
     return (
       <div className="flex  items-center gap-4">
         <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
           {number}
         </div>
         <div>
           <h3 className="font-bold text-lg">{title}</h3>
           <p className="text-gray-600 text-sm">{description}</p>
         </div>
         
       </div>
  )
}

export default CampousSecurity