import React from 'react';
import Ac from '../assets/images/AC.png'
const Features = () => {
  return (
    <section id='features'>
      {/* Flex Container */}
      <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row'>
        {/* What's Different */}
        <div className='flex flex-col space-y-12 md:w-1/2'>
          <h2 className='max-w-md text-4xl font-bold text-center md:text-left'>
          Why Choose Aves Security Management System?

          </h2>
          <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
          Take the first step towards a safer and more efficient future. Aves Security Management System is your trusted partner in security management, offering innovative solutions that empower your team and secure your assets.
          </p>
         <img className='p-4 lg:relative lg:-top-10 lg:p-2 lg:right-8 xl:relative xl:-top-10 xl:p-8 ' src={Ac} alt=''>
         </img>
        </div>

        {/* Numbered List */}
        <div className='flex flex-col space-y-8 md:w-1/2'>
          {/* List Item 1 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-slate-400SupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
                  01
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                Streamlined Guard Scheduling

                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              Streamlined Guard Scheduling

              </h3>
              <p className='text-darkGrayishBlue'>
              From incident reporting to real-time tracking, Aves equips your team with cutting-edge tools to handle every aspect of security management seamlessly.
              </p>
            </div>
          </div>

          {/* List Item 2 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-slate-400SupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
                  02
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                Real-Time Tracking and Reporting
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              Real-Time Tracking and Reporting
              </h3>
              <p className='text-darkGrayishBlue'>
              Stay connected with your team 24/7. Our system provides real-time updates, detailed reports, and actionable data for better decision-making.
              </p>
            </div>
          </div>

          {/* List Item 3 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-slate-400SupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
                  03
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                Easy-to-Use Mobile and Desktop Interfaces
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              Easy-to-Use Mobile and Desktop Interfaces
              </h3>
              <p className='text-darkGrayishBlue'>
              Aves adapts to businesses of all sizes. Whether you manage a small team or a global operation, we tailor our solutions to meet your specific requirements.
              </p>
            </div>
          </div>
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-slate-400SupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
                  04
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                Enhanced Accountability
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              Enhanced Accountability
              </h3>
              <p className='text-darkGrayishBlue'>
              Track team performance, reduce errors, and ensure compliance with automated workflows and digital logs.
              </p>
            </div>
          </div>
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-slate-400SupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
                  05
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                User-Friendly Interface
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              User-Friendly Interface
              </h3>
              <p className='text-darkGrayishBlue'>
              Our platform is designed for simplicity, making it easy for your team to navigate and use effectively, even with minimal training.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 className='text-5xl font-bold flex justify-center items-center text-[#27335f] pb-5'>Our Features</h1>
        <p className='flex justify-center items-center text-[#27335f] pb-5'>Designed for Superior Security Management</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 ">
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md  ">
        <h1 className='text-2xl font-bold pb-1'>Easy Management</h1>
        <p className='text-sm font-normal text-slate-500'>Handle everything in one placestaff schedules, reports, and tasksall connected and simple to use</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md  ">
        <h1 className='text-2xl font-bold pb-1'>Simple Design
        </h1>
        <p className='text-sm font-normal text-slate-500'>Our app is easy to understand and use, making your work faster and stress-free.</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md  ">
        <h1 className='text-2xl font-bold pb-1'>Team Collaboration</h1>
        <p className='text-sm font-normal text-slate-500'>Enhance teamwork with built-in communication tools, allowing your staff to share updates, tasks, and ideas seamlessly</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md  ">
        <h1 className='text-2xl font-bold pb-1'>Flexible Option</h1>
        <p className='text-sm font-normal text-slate-500'>
        Change features to match what your team or business needs, so the app works best for you.</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md  ">
        <h1 className='text-2xl font-bold pb-1'>Real time Update</h1>
        <p className='text-sm font-normal text-slate-500'>
        Get live updates and quick reports to make better decisions without waiting.</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md  ">
        <h1 className='text-2xl font-bold pb-1'>Safe And Secure</h1>
        <p className='text-sm font-normal text-slate-500'>
        Keep your data safe and meet all rules with built-in security and tracking systems</p>
      </div>
    </div>
    </section>
  );
};

export default Features;
