import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homes from './components/Homes';
import About from './components/About';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Service from './components/Service'
import Plans from './components/Plans'
function App() {
  return (
    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route path="/" element={<Homes />} />
        <Route path="/about" element={<About />} />
        <Route path='/service' element={<Service/>}/>
        <Route path='/plans' element={<Plans/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
