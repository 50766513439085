import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Andriod from '../assets/ResidantalCommunities.png'
import Ios from '../assets/ResitalInspection.png'
import HowIt from './HowIt';
import { Check } from 'lucide-react';
import SecurityMale from '../assets/ResidentalMain.png'
import HospitaltyBanner from '../assets/ResidentalBanner.png'
import Restarant from '../assets/GATED COMMUNITIES.png'
import Event from '../assets/HOSUING SOCITIES.png'
import Resort from '../assets/PRIVATE RESIDENT.png'
import ConventionCenter from '../assets/ESTATE.png'
import Tour from '../assets/TOWNSHIP.png'

function ResidentialComplex() {
  return (
    <section>
    <div className='bg-white'>
      <div>
        <img src={SecurityMale} alt="Security App" className="w-screen" />
        <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        RESIDENTIAL & COMMUNITY
  </h1>
  <h3 className='font-light p-3'>
  SECURITY MANAGEMENT SYSTEM
  </h3>
        </span>
      </div>
      <div className='bg-[#F7F7F7]'>
      <h1 className='text-3xl font-bold text-center pt-7  '>
      REASONS TO IMPLEMENT THE AVES SECURITY MANAGEMENT SYSTEM
      </h1>
      <h2 className='text-3xl font-bold text-center pb-2'>
      RESIDENTIAL AND COMMUNITY SETTINGS
      </h2>
      <div className="text-center p-5 text-gray-500 font-semibold space-y-4 max-w-2xl mx-auto leading-relaxed pt-3">
  <p>
    AVES is a comprehensive Security Management System tailored for the digital oversight of security operations 
    in residential areas, gated communities, and apartment buildings. 
  </p>
  <p>
    It offers organized documentation, real-time updates, and compliance monitoring, facilitating effective 
    incident management, visitor oversight, and coordination of security personnel without the need for extra hardware.
  </p>
  <p>
    By digitally managing security patrols, access records, and emergency response efforts, AVES promotes 
    transparency, accountability, and operational efficiency in residential security.
  </p>
</div>

      <div className="bg-[#F7F7F7] p-6 md:p-12">
      {/* Header Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
     WHY SHOULD RESIDENT ADOPT THE AVES SECURITY GOVERNANCE SYSTEM?

      </h2>
      </div>

      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Andriod}
            alt="Security App"
            className="w-full max-w-md rounded-3xl shadow-xl"
          />
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                   <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
    <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="bg-white p-6 md:p-12">
    <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
    MAJOR CHARACTERISTICS OF OUR AVES RESIDENT SECURITY SOFTWARE SYSTEM
      </h2>
      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Features Section */}
        <div className="space-y-6">
          {feature.map((feature, index) => (
            <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
                    <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
    <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Ios}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
    </div>
    <div>
    </div>
    <div className="relative w-full">
      {/* Background Image */}
      <img
        src={HospitaltyBanner}
        alt="Hospitality Security"
        className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
      />

      {/* Text Overlay */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
        <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold pb-3">
        Get Started Today  
        </h2>
        <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
Experience the best mobile app for security with Aves – a state of the art Security  Governance System for your Education world.

        </p>

        {/* CTA Button */}
        <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
          Contact Us →
        </button>
      </div>
    </div>
    <div className="px-6 lg:px-20 py-10 bg-gray-100">
      {/* Title Section */}
      <div className="text-center mb-10">
        <h2 className="text-2xl font-bold uppercase text-gray-800">
          FOR WHOM WE ARE BUILDING
        </h2>
        <h3 className="text-xl font-semibold text-gray-700">
        OUR RESIDENTIAL SECURITY SOFTWARE SYSTEM
        </h3>
      </div>
      
     {/* Icon Section */}
     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">GATED COMMUNITIES</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">HOUSING SOCITIES</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl  flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">PRIVATE RESIDENTIAL</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">ESTATES</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">TOUNSHIPS</p>
  </div>
</div>
    </div>
     <div className='bg-white '>
              <div className="mt-16 text-center px-10 py-10 rounded-xl">
                <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
              
                <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
               {/* Step 1 */}
               <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
                   1
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Register to run residential security operations online.
                 </p>
               </div>
           
               {/* Step 2 */}
               <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
                   2
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
                 <p className="mt-2 text-gray-600 text-sm">
             Install visitor logs, incident reporting protocols, and patrol administration systems.
                 </p>
               </div>
           
               {/* Step 3 */}
               <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
                   3
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Start Governing Security Operations</h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Ensure controlled management of visitor entries, security patrols, and emergency response.

                 </p>
               </div>
             </div>
              </div>
              </div>
\
    </section>
  )
}
const features = [
  {
    title: "COMPREHENSIVE SECURITY SOLUTIONS FOR RESIDENTIAL COMMUNITIES",
    description: "AVES offers a complete Residential Communities Security solution aimed at optimizing and improving security operations for gated communities, apartment complexes, and residential neighborhoods."
  },
  {
    title: "REAL-TIME SURVEILLANCE",
    description: "Maintain an edge with immediate notifications and real-time information readily available, facilitating proactive security oversight in residential communities."
  },
  {
    title: "CUSTOMIZABLE AND SCALABLE",
    description: "The Security Guard Management Platform for Residential Communities Security is adaptable to fulfill the specific requirements of your community, offering a flexible and scalable security structure."
  
  },  {
    title: "USER-FRIENDLY AND INTUITIVE",
    description: "Our Security Options for Gated Communities provide seamless integration with existing systems, ensuring an efficient and user-friendly experience."
  },
  {
    title: "DATA-DRIVEN SECURITY STRATEGIES",
    description: "Utilize actionable insights and analytics to enhance security protocols throughout your residential security systems."
  }
];

const feature = [
  {
    title: "VISITOR AND ACCESS CONTROL MANAGEMENT",
    description: "Document digitally all persons, vendors, and deliveries that access the property.Track guest passes issued by residents and manage approvals for security clearances",
  },
  {
    title: "INCIDENT REPORTING AND COMMUNITY SAFETY MONITORING",
    description: "Record suspicious activities, security incidents, and resident concerns digitally.Maintain organized documentation of emergencies, vandalism, and disturbances"
  },
  {
    title: "SECURITY PATROL AND GUARD MANAGEMENT",
    description: "Supervise assignments for security personnel, patrol schedules, and surveillance tasks.Keep digital records of patrol reports and property inspections"
  },
  {
    title: "EMERGENCY RESPONSE AND SAFETY NOTIFICATIONS",
    description: "Manage fire drills, medical emergencies, and evacuation procedures.Maintain organized records of security responses to alarms and distress calls."
  },
  {
    title: "RESIDENTIAL SECURITY MONITORING",
    description: "Track key issuance for apartments, common areas, and restricted areas.Monitor security passes for maintenance staff, contractors, and external service providers."
  }
]

const Step = ({ number, title, description }) => {
  return (
    <div className="flex  items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
      
    </div>
  )
}

export default ResidentialComplex;