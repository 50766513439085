import React from 'react'
import Form from '../components/Form';
import Andriod from '../assets/Corprate.png'
import Ios from '../assets/corprateWomen.png'
import HowIt from './HowIt';
import { Check } from 'lucide-react';
import SecurityMale from '../assets/CorprateBanner.png'
import HospitaltyBanner from '../assets/Corpratemain.png'
import Restarant from '../assets/CorprateOfficer.png'
import Event from '../assets/TechCampous.png'
import Resort from '../assets/ManufacturingUnits.png'
import ConventionCenter from '../assets/RetailAndChanins.png'
import Tour from '../assets/Graduate.png'


function CorprateSecurity() {
  return (
    <section>
    <div className='bg-white'>
      <div >
        <img src={SecurityMale} alt="Security App" className="w-screen" />
        <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        CORPORATE
  </h1>
  <h3 className='font-light'>
  SECURITY MANAGEMENT SYSTEM
  </h3>
        </span>
      </div>
      <div className='bg-[#F7F7F7]'>
     {/* <h1 className='text-3xl font-bold text-center pt-7  '>
      WHY SHOULD SOMEONE DOWNLOAD
      </h1>
      <h2 className='text-3xl font-bold text-center pb-2'>
      OUR CORPORATE SECURITY SYSTEM
      </h2>*/}
 <div className="text-center p-5 text-gray-500 font-semibold  text-lg pt-10 space-y-1">
  <p>AVES is a specialized Corporate Security Software that offers organized management.</p>
  <p>It ensures compliance and operational oversight for security systems in corporate offices.</p>
  <p>By utilizing a centralized digital platform, it streamlines security guard tracking.</p>
  <p>It also manages visitor access, approval processes, and incident reporting efficiently.</p>
</div>

      <div className="bg-[#F7F7F7] p-6 md:p-12">
      {/* Header Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
      WHY SHOULD SOMEONE DOWNLOAD AVES SECURITY MANAGEMENT SYSTEM? 
      </h2>
      </div>

      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Andriod}
            alt="Security App"
            className="w-full max-w-xl rounded-3xl shadow-xl"
          />
        </div>

        {/* Features Section */}
        <div className="space-y-6 ">
          {features.map((feature, index) => (
            <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                   <span className="mt-4 bg-gray-600 text-white w-6 h-6 text-sm font-black p-[10px] relative  top-[-10px] rounded-full">
    <Check className='w-4 h-4 relative bottom-[6px] right-[5px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="bg-white p-6 md:p-12">
    <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
    MAJOR CHARACTERISTICS OF OUR AVES CORPORATE  SECURITY SOFTWARE SYSTEM
      </h2>
      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Features Section */}
        <div className="space-y-6">
          {feature.map((feature, index) => (
            <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
                    <span className="mt-4 bg-gray-600 text-white w-6 h-6 text-sm font-black  p-[10px] relative  top-[-10px] rounded-full">
    <Check className='w-4 h-4 relative bottom-[5px] right-[5px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
                <p className="text-gray-600 text-sm">{feature.descriptions}</p>
                
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Ios}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
    </div>
    <div>
    </div>
    <div className="relative w-full">
      {/* Background Image */}
      <img
        src={HospitaltyBanner}
        alt="Hospitality Security"
        className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
      />

      {/* Text Overlay */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
        <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
        Get Started Today 
        </h2>
        <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
        Enhance security, streamline operations, and ensure compliance with our advanced governance system. Take control of your security protocols with efficiency and precision.
        </p>

        {/* CTA Button */}
        <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
          Contact Us →
        </button>
      </div>
    </div>
    <div className="px-6 lg:px-20 py-10 bg-gray-100">
      {/* Title Section */}
      <div className="text-center mb-10">
        <h2 className="text-2xl font-bold uppercase text-gray-800">
          FOR WHOM WE ARE BUILDING
        </h2>
        <h3 className="text-2xl font-bold text-gray-800">
      OUR  CORPRATE SECURITY SOFTWARE SYSTEM
        </h3>
      </div>
      
          {/* Icon Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Corporate Offices</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Tech Campuses</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Manufacturing Units</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Retail Chains</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Educational Institutions</p>
  </div>
</div>
{/* How It Works Section */}

    </div>
     <div className='bg-white '>
              <div className="mt-16 text-center px-10 py-10 rounded-xl">
                <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
              
                <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
               {/* Step 1 */}
               <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
                   1
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Register to digitally govern corporate security operations in your business.
                 </p>
               </div>
           
               {/* Step 2 */}
               <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
                   2
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Configure incident reporting workflows, visitor logs, and access control tracking.
                 </p>
               </div>
           
               {/* Step 3 */}
               <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
                   3
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Start Governing Security Operations
                 </h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Maintain structured digital records, automate incident escalation, and ensure compliance.
                 </p>
               </div>
             </div>
              </div>
              </div>

    </section>
  )
}
const features = [
  {
    title: "COMPREHENSIVE SECURITY SOLUTIONS FOR CORPORATE PROTECTION",
    description: "AVES offers a complete Corporate Security Software solution aimed at optimizing and improving your organization's security processes"
  },
  {
    title: " REAL-TIME SURVEILLANCE",
    description: "Maintain an edge with immediate notifications and real-time information readily available, facilitating proactive security oversight"
  },
  {
    title: "CUSTOMIZABLE AND SCALABLE",
    description: "The Corporate Security Governance system is adaptable to fulfill the specific requirements of your organization, offering a flexible and scalable security structure."
  },
  {
    title: "USER-FRIENDLY AND INTUITIVE",
    description: "Our Security Guard Management Platform for Corporate Security is crafted for easy integration, minimizing implementation time through a user-friendly interface."
  },
  {
    title: "DATA-DRIVEN SECURITY STRATEGY",
    description: "Utilize actionable insights and analytics to enhance security protocols throughout your corporate setting."
  }
];

const feature = [
  {
    title: "COMPLIANCE & SECURITY ACCOUNTABILITY",
    description: "Ensure that all corporate security protocols, key management practices, and employee access are thoroughly documented.",
    descriptions: " Keep records ready for audits, including incident reports, visitor logs, and security inspections.",
  },
  {
    title: "INCIDENT REPORTING & ESCALATION",
    description: "Record, track, and escalate security incidents, lost access cards, and compliance violations digitally. ",
     descriptions: "Streamline approval processes and accountability tracking for essential security decisions through automation."
  },
  {
    title: "VISITOR & CONTRACTOR GOVERNANCE",
    description: "Optimize the check-in experience for visitors and contractors by keeping digital records well-organized.",
     descriptions: "Introduce real-time tracking of arrivals and departures to minimize the potential for unauthorized access."
  },
  {
    title: "SECURITY PASS & GATE PASS TRACKING",
    description: "Manage temporary access passes for vendors, suppliers, and third-party service providers in a digital format. ",
     descriptions: "Keep systematic records of all incoming and outgoing shipments."
  },
  {
    title: "SECURITY BRIEFINGS & TRAINING COMPLIANCE",
    description: "Get weekly analytica reports for your security mangement and security measures enhancement.",
     descriptions: "Maintain a historical log of training completions to support corporate governance."
  }
]

const Step = ({ number, title, description }) => {
  return (
    <div className="flex  items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
      
    </div>
  );
};

export default CorprateSecurity