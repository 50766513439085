import React from "react";
import video from '../assets/Video.png';
import { TiDownloadOutline } from "react-icons/ti";

import Inovation from '../assets/Inovation.png'
import Intergrity from '../assets/Integrity.png'
import Excellence from '../assets/Excellence.png'
import playstore from '../assets/Playstore.png'
import appstore from '../assets/AppStrore.png'
import MobileAves from '../assets/MobileAves.png'
import MobileAvesnotify from '../assets/MobileAvesNotify.png'
import Form from "./Form";
import Akar from '../assets/Framer.png';
import Alarm from '../assets/Real.png';
import frame from '../assets/Frame.png';
import New from '../assets/new.png';
import Old from '../assets/Old.png';
import Heart from '../assets/Heart.png';
import layer from '../assets/Layer_1.png';
import Line from '../assets/Line.png';
import Desk from '../assets/Desk.png';
import Hand from '../assets/Hand.png';
import Cash from '../assets/cash.png';
import Book from '../assets/Book.png';
import Img from '../assets/Img.png';
import Eco from '../assets/Eco.png';
import Export from '../assets/NewPaper.png';
import Flex from '../assets/Flex.png'
import Action from '../assets/Action.png'
import Like from '../assets/Like.png'
import Goal from '../assets/Export.png'


const Features = () => {
  const cards = [
    {
      title: "Streamlined Guard Scheduling",
      description: "AVES Security Management brings everything you need into one app. Whether it’s managing teams, tracking incidents, or ensuring compliance.",
      icon: Akar,
    },
    {
      title: "Real-Time Tracking and Reporting",
      description: "Keep track of incidents and reports in real-time. AVES Security Management ensures you never miss a thing.",
      icon: Alarm,
    },
    {
      title: "Easy-to-Use Mobile and Desktop Interfaces",
      description: "Our platform works seamlessly across devices, making it accessible and efficient for all users.",
      icon: frame,
    },
    {
      title: "User-Friendly Interface",
      description: "Navigate effortlessly through our intuitive and straightforward interface.",
      icon: New,
    },
    {
      title: "Enhanced Accountability",
      description: "Monitor team performance and ensure compliance with automated workflows.",
      icon: Old,
    },
    {
      title: "Customizable For Your Specific Needs",
      description: "AVES adapts to businesses of all sizes, tailored to meet your specific requirements.",
      icon: Heart,
    },
  ];

  const system = [
    {
      title: "Real-Time Monitoring & Reporting",
      description: "Stay connected with your team 24/7. Our system provides real-time updates, detailed reports, and actionable data for better decision-making.",
      icon: layer,
      color: "#ec4dbc",  // Pink
    },
    {
      title: "Streamlined Guard Scheduling",
      description: "From incident reporting to real-time tracking, AVES equips your team with cutting-edge tools to handle every aspect of security management.",
      icon: Line,
      color: "#4dabec",  // Blue
    },
    {
      title: "Easy-to-Use Mobile and Desktop Interfaces",
      description: "AVES adapts to businesses of all sizes. Whether you manage a small team or a global operation, we tailor our solutions to meet your specific requirements.",
      icon: Desk,
      color: "#4bec85",  // Green
    },
    {
      title: "Enhanced Accountability",
      description: "Track team performance, reduce errors, and ensure compliance with automated workflows and digital logs.",
      icon: Hand,
      color: "#f3b33d",  // Yellow
    },
    {
      title: "User-Friendly Interface",
      description: "Our platform is designed for simplicity, making it easy for your team to navigate and use effectively, even with minimal training.",
      icon: Cash,
      color: "#9453e8",  // Purple
    },
    {
      title: "Customizable For Your Specific Needs",
      description: "AVES adapts to businesses of all sizes, tailored to meet your specific requirements.",
      icon: Akar,
      color: "#ff5733",  // Orange
    },
  ];

  const features = [
    {
      title: "Easy Management",
      description: "It is a one-stop solution that deals with staff schedules, reports, and tasks; they are unified, and the user interface is clear.",
      icon: Book,
      color: "#4dabec",
    },
    {
      title: "Simple Design",
      description: "These include With our app, we make work easier, faster and less stressful through easy to understand options",
      icon: Img,
      color: "#4bec85",
    },
    {
      title: "Flexible Options",
      description: "It offers real time information and quick ba­sic reports so that decisions need not wait.",
      icon: Export,
    },
    {
      title: "Real-Time Updates",
      description: "It offers real time information and quick ba­sic reports so that decisions need not wait.",
      icon:Eco ,
    },
    {
      title: "Flexible Options",
      description: "It offers real time information and quick ba­sic reports so that decisions need not wait.",
      icon: Like,
    },
    {
      title: "Safe and Secure",
      description: "Collect and maintain your data securely and track all these processes with the help of integrated Security Governance System features.",
      icon:Goal,
    },
    {
      title: "Eco-Friendly",
      description: "Save our planet by minimizing the use of papers and carrying out all tasks online.",
      icon: Action,
    },
    {
      title: "Call-to-Action(CTA)",
      description: "Manage security with AVES go paperless, organize data, and generate reports in a few clicks!",
      icon: Flex,
    },

  ];

  return (
    <div className="px-4 py-8 md:py-12 lg:py-16 bg-white">
      {/* Why Choose Us Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">
        Why Choose Us?
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {cards.map((card, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300"
          >
            <div></div>
            <img src={card.icon} alt={card.title} className="w-16 h-16 mb-4 object-contain" 
              />
            <h3 className="text-lg font-semibold mb-2 text-center">
              {card.title}
            </h3>
            <p className="text-gray-600 text-center">{card.description}</p>
          </div>
        ))}
      </div>

      {/* Aves Security Management Section */}
      <div>
  <h1 className="mt-10 flex justify-center items-center text-3xl font-bold text-center text-gray-800 pb-5">
    Aves Security Management System
  </h1>
  <p className="text-center text-gray-600 mb-5 font-medium">
    Make your security management easier today. Go paperless with AVES
    Security Management. Sort, filter, and create reports with just a
    click!
  </p>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    {system.map((sys, index) => (
      <div
        key={index}
        className="flex flex-col items-center p-6 bg-white shadow-md rounded-lg hover:shadow-2xl transition-shadow duration-500"
      >
        <div
          className="w-16 h-16 mb-4 p-3 rounded-full"
          style={{ backgroundColor: sys.color }}
        >
          <img src={sys.icon} alt={sys.title} className="w-full h-full object-contain" />
        </div>
        <h3 className="text-lg font-semibold mb-2 text-center">
          {sys.title}
        </h3>
        <p className="text-gray-600 text-center">{sys.description}</p>
      </div>
    ))}
  </div>
</div>

      {/* Features Section */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <h3 className="text-2xl font-bold text-center mb-12">
            OUR FEATURES
          </h3>
          <p className="pb-10 text-center text-gray-600 mb-5 font-medium">
            Designed for Superior Security Management. Make your security management easier today. Go Paperless with 
            AVES Security Management. Sort, filter, and create reports with just a click!
          </p>
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 ">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg text-center shadow-md  hover:scale-105 transition duration-300 ease-in-out"
              >
                <img src={feature.icon} alt={feature.title} className="w-16 h-16 mb-4 object-contain flex items-center justify-center mx-auto" />
                <h4 className="text-lg font-semibold mt-4">{feature.title}</h4>
                <p className="text-sm mt-2">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Black Section */}
      <div className="bg-[#2E2D2D] text-white py-32">
        <h1 className="text-4xl text-center mt-0 pb-16 font-bold">
          Streamline Your Security Operations Today
        </h1>
        <p className="font-semibold text-center text-slate-300 mb-5 text-base">
          Take the first step towards a safer and more efficient future. Aves
          Security Management System is your trusted partner in security
          management, offering innovative solutions that empower your team and
          secure your assets.
        </p>
        <button className='flex justify-center items-center mx-auto bg-[#FABC3F] text-gray-900 font-semibold px-8 py-3 rounded-lg hover:bg-[#f0c46c] hover:scale-105 transition duration-300 ease-in-out'>
Learn More
      </button>
      </div>
     {/* Play Store Section */}
<div className="p-6 relative top-7 bottom-44">
  <section className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
    {/* Text & Download Buttons */}
    <div>
      <h1 className="text-3xl font-bold mb-4">Download App Now</h1>
      <p className="text-gray-600 mb-6">
        "Whether you’re managing a small team or a large-scale operation, AVES Security Management offers plans tailored to fit your needs. Each plan is designed to provide the tools and features necessary to streamline your security processes while maintaining cost-effectiveness."
      </p>
      <div className="flex gap-4 justify-center sm:justify-start">
        <img src={playstore} alt="Google Play" className="w-32 h-auto" />
      </div>
    </div>

    {/* Mobile Screens */}
    <div className="flex justify-center gap-6">
      <img src={MobileAves} alt="App Screen 1" className="w-40 h-auto mx-auto" />
      <img src={MobileAvesnotify} alt="App Screen 2" className="w-40 h-auto mx-auto" />
    </div>
  </section>

  {/* Innovation, Integrity, Excellence Cards */}
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
    {/* Innovation */}
    <span className="shadow-lg shadow-gray-200 rounded-lg p-6 bg-[#e7a9d4] text-center">
      <img src={Inovation} alt="Innovation" className="mx-auto w-16 h-16" />
      <h1 className="text-xl font-extrabold text-[#76482E] pt-4 pb-2">100+</h1>
      <p className="text-xl font-extrabold text-[#76482E] ">
    DOWNLOAD
      </p>
    </span>

    {/* Integrity */}
    <span className="shadow-lg shadow-gray-200 rounded-lg p-6 bg-[#96ec95] text-center">
      <img src={Intergrity} alt="Integrity" className="mx-auto w-16 h-16" />
      <h1 className="text-xl font-extrabold text-[#216320] pt-4 pb-2">500+</h1>
      <p className="text-xl font-extrabold text-[#216320] ">
    LIKE
      </p>
    </span>

    {/* Excellence */}
    <span className="shadow-lg shadow-gray-200 rounded-lg p-6 bg-[#f0bb9e] text-center">
      <img src={Excellence} alt="Excellence" className="mx-auto w-16 h-16" />
      <h1 className="text-xl font-extrabold text-[#4F2F47] pt-4 pb-2">1500+</h1>
      <p className="text-xl font-extrabold text-[#4F2F47] ">
      5 star Rating
      </p>
    </span>
  </div>
</div>
    </div>
  );
};

export default Features;
