import { Link } from 'react-router-dom';
import twitterLogo from '../assets/images/icon-twitter.svg';
import instagramLogo from '../assets/images/icon-instagram.svg';
import facebookLogo from '../assets/images/icon-facebook.svg';
import youtubeLogo from '../assets/images/icon-youtube.svg';
import Aves from '../assets/AvessecurityLogo.png';

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="bg-black text-white py-10">
      <div className="container mx-auto px-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-10">
        
        {/* Column 1: Logo & Social Media */}
        <div className="flex flex-col items-center md:items-start space-y-6">
          <Link to="/" onClick={handleScrollToTop}>
            <img src={Aves} alt="Aves Logo" className="w-40" />
          </Link>
          <div className="flex space-x-4">
            <a href="https://x.com/AvesSystem" target="_blank" rel="noopener noreferrer">
              <img src={twitterLogo} alt="Twitter" className="h-8" />
            </a>
            <a href="https://www.instagram.com/avessecurity/" target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" className="h-8" />
            </a>
            <a href="https://www.facebook.com/avessystem" target="_blank" rel="noopener noreferrer">
              <img src={facebookLogo} alt="Facebook" className="h-8" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={youtubeLogo} alt="YouTube" className="h-8" />
            </a>
          </div>
        </div>

        {/* Column 2: Quick Links */}
        <div className="flex flex-col space-y-2 text-center md:text-left">
          <p className="text-lg font-semibold">Quick Links</p>
          <Link to="/" onClick={handleScrollToTop} className="hover:text-gray-400">Home</Link>
          <Link to="/about" onClick={handleScrollToTop} className="hover:text-gray-400">About Us</Link>
          <Link to="/service" onClick={handleScrollToTop} className="hover:text-gray-400">Service</Link>
          <Link to="/plans" onClick={handleScrollToTop} className="hover:text-gray-400">Plans</Link>
        </div>

        {/* Column 3: Services */}
        <div className="flex flex-col space-y-2 text-center md:text-left">
          <p className="text-lg font-semibold">Services</p>
          <Link to="/Hospitality" onClick={handleScrollToTop} className="hover:text-gray-400">Hospitality</Link>
          <Link to="/Corporate" onClick={handleScrollToTop} className="hover:text-gray-400">Corporate</Link>
          <Link to="/Residential" onClick={handleScrollToTop} className="hover:text-gray-400">Residential</Link>
          <Link to="/Healthcare" onClick={handleScrollToTop} className="hover:text-gray-400">Healthcare</Link>
        </div>
        <div className='flex flex-col space-y-2 text-center md:text-left'>
          <Link></Link>
        <Link to="/Government" onClick={handleScrollToTop} className="hover:text-gray-400">Government</Link>
          <Link to="/Education" onClick={handleScrollToTop} className="hover:text-gray-400">Education</Link>
          <Link to="/Logistics" onClick={handleScrollToTop} className="hover:text-gray-400">Campus Security</Link>
          <Link to="/Event" onClick={handleScrollToTop} className="hover:text-gray-400">Event</Link>
          <Link to="/AuxiliaryPolice" onClick={handleScrollToTop} className="hover:text-gray-400">Auxiliary Police</Link>
        </div>

        {/* Column 4: Contact Us */}
        <div className="flex flex-col space-y-2 text-center md:text-left">
          <p className="text-lg font-semibold">Contact Us</p>
          {/* <span>aves.managementsys@gmail.com</span>*/}
          <span>29 Jln Perdana 2/5, Pulai Perdana 2, Kangkar Skudai, Malaysia, 81300</span>
          <span>+60 11-7030 7031</span>
        </div>

      </div>
    </div>
  );
};

export default Footer;
