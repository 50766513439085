import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Andriod from '../assets/EducationInspector.png'
import Ios from '../assets/InspectionEducation.png'
import HowIt from './HowIt';
import Mobile from '../assets/images/iPhone .png'
import { Check } from 'lucide-react';
import Eduation from '../assets/EducationCaller.png'
import SecurityMale from '../assets/EducationBanner.png'
import HospitaltyBanner from '../assets/EducationMain.png'
import Restarant from '../assets/Schools.png'
import Event from '../assets/College.png'
import Resort from '../assets/university.png'
import ConventionCenter from '../assets/Coaching.png'
import Tour from '../assets/Training.png'
 
function EducationApp() {
  return (
    <section>
    <div className='bg-white'>
      <div>
        <img src={SecurityMale} alt="Security App" className="w-screen" />
        <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        EDUCATION
  </h1>
  <h3 className='font-light pt-2'>
  SECURITY GOVERNANCE SYSTEM
  </h3>
        </span>
      </div>
      <div className='bg-[#F7F7F7]'>
      <div className="text-center p-5 text-gray-500 font-semibold space-y-4 max-w-2xl mx-auto leading-relaxed pt-10">
  <p>
    AVES is a specialized Security Governance System aimed at providing organized oversight, compliance, 
    and operational management for educational entities such as schools, colleges, universities, and training centers.
  </p>
  <p>
    This system offers a comprehensive solution for managing security operations, visitor control, 
    access regulation, incident reporting, and emergency preparedness via a centralized digital platform, 
    requiring only mobile devices and computers without the need for additional hardware.
  </p>
  <p>
    By using AVES, educational institutions can proficiently track campus security logs, 
    thereby guaranteeing a secure environment for students, faculty, and staff.
  </p>
</div>

      <div className="bg-[#F7F7F7] p-6 md:p-12">
      {/* Header Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
      WHY SHOULD EDUCATIONAL INSTITUTIONS ADOPT THE AVES SECURITY GOVERNANCE SYSTEM?
      </h2>
      </div>

      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Andriod}
            alt="Security App"
            className="w-full max-w-lg rounded-3xl shadow-xl"
          />
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
           <span className="mt-4 bg-gray-600 text-white w-6 h-6 text-sm font-black p-[10px] relative  top-[-10px] rounded-full">
                          <Check className='w-4 h-4 relative bottom-[6px] right-[5px] text-center' />
                      </span> 
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="bg-white p-6 md:p-12">
    <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
    MAJOR CHARACTERISTICS OF OUR AVES EDUCATION SECURITY SOFTWARE SYSTEM
      </h2>
      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Features Section */}
        <div className="space-y-6">
          {feature.map((feature, index) => (
            <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
           <span className="mt-4 bg-gray-600 text-white w-6 h-6 text-sm font-black p-[10px] relative  top-[-10px] rounded-full">
                          <Check className='w-4 h-4 relative bottom-[6px] right-[5px] text-center' />
                      </span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
                <p className="text-gray-600 text-sm">{feature.descriptions}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Ios}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
    </div>
    <div>
    </div>
    <div className="relative w-full">
      {/* Background Image */}
      <img
        src={HospitaltyBanner}
        alt="Hospitality Security"
        className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
      />

      {/* Text Overlay */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
        <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
        Get Started Today  
        </h2>
        <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
        Experience the best mobile app for security with Aves – a state of the art Security  Governance System for your Education world.
        </p>

        {/* CTA Button */}
        <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
          Contact Us →
        </button>
      </div>
    </div>
    <div className="px-6 lg:px-20 py-10 bg-gray-100">
      {/* Title Section */}
      <div className="text-center mb-10">
        <h2 className="text-2xl font-bold uppercase text-gray-800">
          FOR WHOM WE ARE BUILDING
        </h2>
        <h3 className="text-2xl font-semibold text-gray-800">
          OUR EDUCATION SECURITY SOFTWARE SYSTEM
        </h3>
      </div>
      
         {/* Icon Section */}
           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
       {/* First Box */}
       <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
         <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
         <img   className='text-white' src={Restarant} />
         </div>
         <p className="mt-4 font-semibold text-gray-700">Schools</p>
       </div>
     
       {/* Second Box */}
       <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
         <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
         <img   className='text-white' src={Event} />
         </div>
         <p className="mt-4 font-semibold text-gray-700">Colleges</p>
       </div>
     
       {/* Third Box */}
       <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
         <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
         <img   className='text-white' src={Resort} />
         </div>
         <p className="mt-4 font-semibold text-gray-700">Universities</p>
       </div>
     
       {/* Fourth Box */}
       <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
         <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
         <img   className='text-white' src={ConventionCenter} />
         </div>
         <p className="mt-4 font-semibold text-gray-700">Coaching Centers</p>
       </div>
     
       {/* Fifth Box */}
       <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
         <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
         <img   className='text-white' src={Tour} />
         </div>
         <p className="mt-4 font-semibold text-gray-700">Training Institutions</p>
       </div>
     </div>

{/* How It Works Section */}

   </div>
   <div className='bg-white '>
   <div className="mt-16 text-center px-10 py-10 rounded-xl">
     <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
   
     <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
    {/* Step 1 */}
    <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
      <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
        1
      </div>
      <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
      <p className="mt-2 text-gray-600 text-sm">
      Join to electronically manage security activities in schools and colleges.
      </p>
    </div>

    {/* Step 2 */}
    <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
      <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
        2
      </div>
      <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
      <p className="mt-2 text-gray-600 text-sm">
      Create incident reporting procedures, visitor access logs, and security compliance reports.
      </p>
    </div>

    {/* Step 3 */}
    <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
      <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
        3
      </div>
      <h3 className="mt-4 text-lg font-bold text-gray-700">Start Managing Campus Security</h3>
      <p className="mt-2 text-gray-600 text-sm">
      Make digital records efficiently, automate escalations, and implement security policy compliance
      </p>
    </div>
  </div>
   </div>
   </div>

    </section>
  )
}
const features = [
  {
    title: "COMPREHENSIVE SECURITY SOLUTIONS FOR CAMPUS PROCECTIONS",
    description: "AVES offers a complete Campus Security Management solution aimed at optimizing and improving security operations across educational institutions"
  },
  {
    title: "REAL TIME SURVELLIANCE",
    description: "Maintain an edge with immediate notifications and real-time information readily available, facilitating proactive security oversight in campus environments"
  },
  {
    title: "CUSTOMIZABLE AND SCALABLE",
    description: "The Cloud-Based Security Guard Management Platform for Campus Security is adaptable to fulfill the specific requirements of your institution, offering a flexible and scalable security structure."
  },
  {
    title: "USER FRIENDLY AND INTUITIVE",
    description: "Our Campus Security Management App is crafted for easy integration, minimizing implementation time through a user-friendly interface"
  },
  {
    title: "DATA DRIVEN STRATEGIES",
    description: "Utilize actionable insights and analytics to enhance security protocols throughout your campus security system."
  }
];

const feature = [
  {
    title: "EMERGENCY RESPONSE & DRILL MANAGEMENT",
    description: "Keep organized records of emergency preparedness, evacuation strategies, and drill activity logs.",
    descriptions:"Facilitate campus-wide collaboration for managing crises, including fire drills, lockdown procedures, and medical emergencies."
  },
  {
    title: "VISITOR AND CONTRACTOR MANAGEMENT",
    description: "Supervise and track the entry of visitors and contractors into school buildings.",
    descriptions:" Establish organized check-in and check-out procedures to ensure adherence to security protocols"
  },
  {
    title: "GATE PASS AND EQUIPMENT OVERSIGHT",
    description: "Carefully document emergency drills and security training programs.",
    descriptions:"Keep a detailed log of lost and found items at the school"
  },
  {
    title: "EMERGENCY DRILL AND CRISIS MANAGEMENT",
    description: "Streamline the check-in process for guests, vendors, and contractors. Maintain digital records for easy tracking and accountability.",
    descriptions:" Improve communication and teamwork between security s during emergencies."
  },
  {
    title: "EMERGENCY DRILL & CRISIS MANAGEMENT",
    description: "Maintain detailed records of emergency drills and security training activities.",
    descriptions:"Facilitate cooperation between security personnel and faculty during emergency situations."
  }
]

const Step = ({ number, title, description }) => {
  return (
    <div className="flex  items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
      
    </div>
    
  )
}

export default EducationApp