import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Mobile from '../assets/images/iPhone .png'
import Andriod from '../assets/Android.png'
import Ios from '../assets/Ios.png'
import HowIt from './HowIt';

function Hospitality() {
  return (
    <section>
    <div className='bg-white'>
      <h1 className='text-3xl font-bold text-center pt-6 pb-2'>
      Hospitality Security App
      </h1>
      <p className='text-center text-gray-500 pb-5 p-10'>
      At AVES Security Management App we focus on offering solutions for the hospitality business sector. To our valuable guest: Our hospitality security app provides safety, efficiency, and ease to our hospitality services. From hotel chain to resort or an event hall, AVES provides the means to protect both the guests and the staff in addition to the property itself.
      </p>
      <div className="bg-white p-6 md:p-12">
      {/* Header Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
        WHY SHOULD SOMEONE DOWNLOAD OUR HOSPITALITY SECURITY APP?
      </h2>

      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Andriod}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-start gap-3">
              <span className="text-green-600 text-xl">✔</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="bg-white p-6 md:p-12">
      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Features Section */}
        <div className="space-y-6">
          {feature.map((feature, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg flex items-start gap-3">
              <span className="text-green-600 text-xl">✔</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Ios}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
    </div>
    <HowIt/>
    <div>
    <div className="flex justify-center text-left pb-5">
          <Step number="3" title="Access Security Features" description="View reports, checklists, and real-time alerts instantly." />
        </div>
      <h1 className='text-3xl font-bold text-center pt-6 pb-10 p-5'>
      
      </h1>
    </div>
    <Form/>
    </section>
  )
}
const features = [
  {
    title: "Enhanced Guest Safety",
    description: "Protect the visitors accompanying your event with the features of real-time tracking and improved security."
  },
  {
    title: "Operational Efficiency",
    description: "Optimise on security procedures to enhance the time and money needed to run general procedures."
  },
  {
    title: "Customizable Solutions",
    description: "To enhance the rendering of appropriate features, adapt the app to the needs of your hospitality company."
  },
  {
    title: "Real-Time Alerts",
    description: "Receive prompt notification for any event such as accident, crisis or suspicious event."
  },
  {
    title: "Data-Driven Insights",
    description: "Get weekly analytical reports for your security management and security measures enhancement."
  }
];

const feature = [
  {
    title: "Guest Safety Management",
    description: "Protect the visitors accompanying your event with the features of real-time tracking and improved security."
  },
  {
    title: "Access Control for Staff and Guests",
    description: "Optimise on security procedures to enhance the time and money needed to run general procedures."
  },
  {
    title: "Incident Reporting",
    description: "To enhance the rendering of appropriate features, adapt the app to the needs of your hospitality company."
  },
  {
    title: "Visitor Management",
    description: "Receive prompt notification for any event such as accident, crisis or suspicious event."
  },
  {
    title: "Data-Driven Insights",
    description: "Get weekly analytical reports for your security management and security measures enhancement."
  }
]

const Step = ({ number, title, description }) => {
  return (
    <div className="flex  items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  )
}

export default Hospitality