import React from 'react'
import Akar from '../assets/Akar.png';
import Alarm from '../assets/Alarm.png';
import frame from '../assets/Frame.png';
import New from '../assets/new.png';
import Old from '../assets/Old.png';
import Heart from '../assets/Heart.png';
import Aves from '../assets/images/aveslogo.png'
import Hospitality  from '../assets/Hospitality.png'
import Corprate from '../assets/Corprateoffice.png'
import Healthcare  from '../assets/HealthCare.png'
import Education from '../assets/Education.png'
import Retail from '../assets/Retail and shopping.png'
import Manufacture from '../assets/Warehouse.png'
import Goverenment from '../assets/Goverenmnt.png'
import Gated from '../assets/Gated.png'
import Logistics from '../assets/Logiestic.png'
import Form from '../components/Form'
import { Link } from 'react-router-dom'

function Service() {
    const cards = [
        {
          title: "Streamlined Guard Scheduling",
          description: "AVES Security Management brings everything you need into one app. Whether it’s managing teams, tracking incidents, or ensuring compliance.",
          icon: Akar,
        },
        {
          title: "Real-Time Tracking and Reporting",
          description: "Keep track of incidents and reports in real-time. AVES Security Management ensures you never miss a thing.",
          icon: Alarm,
        },
        {
          title: "Easy-to-Use Mobile and Desktop Interfaces",
          description: "Our platform works seamlessly across devices, making it accessible and efficient for all users.",
          icon: frame,
        },
        {
          title: "User-Friendly Interface",
          description: "Navigate effortlessly through our intuitive and straightforward interface.",
          icon: New,
        },
        {
          title: "Enhanced Accountability",
          description: "Monitor team performance and ensure compliance with automated workflows.",
          icon: Old,
        },
        {
          title: "Customizable For Your Specific Needs",
          description: "AVES adapts to businesses of all sizes, tailored to meet your specific requirements.",
          icon: Heart,
        },
      ];
  return (
    <div className=' bg-white'>
        <h1 className='text-3xl font-bold text-center mb-2 pt-5'>
        Smart Solutions for Modern Security Challenges
        </h1>
        <p className='p-8 text-center'>
        AVES Security Management provides a suite of services that address the dynamic needs of today’s security landscape. Whether managing personnel, monitoring activities, or maintaining compliance, our platform simplifies and optimizes your security operations
        </p>
    <div className='mt-10 bg-white mb-10'>
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {cards.map((card, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300"
          >
            <img src={card.icon} alt={card.title} className="w-16 h-16 mb-4 object-contain" />
            <h3 className="text-lg font-semibold mb-2 text-center">
              {card.title}
            </h3>
            <p className="text-gray-600 text-center">{card.description}</p>
          </div>
        ))}
      </div>
      </div>
<div>
    <h1 className='text-3xl font-bold text-center mb-2'>
    Transforming Security Across Diverse Industries
    </h1>
    <p className='text-center text-lg mb-10 p-10'>
    The AVES Security Management App is designed to cater to the unique needs of various industries. With its versatile features and customizable solutions, our platform ensures safety, compliance, and efficiency in any environment. Here’s a look at the industries we empower with our cutting-edge security solutions
    </p>
</div>
<div class="container mx-auto p-5 ">
<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-10 ">
<div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Hospitality} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hospitality</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Managing guest safety, property security, and compliance with regulations.

How We Help: Streamline operations with tools for incident reporting, lost and found management, and staff monitoring.</p>
                <Link to="/Hospitality" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>

        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 2 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Corprate} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Corporate & Office Spaces</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Managing guest safety, property security, and compliance with regulations.

How We Help: Streamline operations with tools for incident reporting, lost and found management, and staff monitoring..</p>
                <Link to="/Corporate" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 3 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Healthcare} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Healthcare</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Managing guest safety, property security, and compliance with regulations.

How We Help: Streamline operations with tools for incident reporting, lost and found management, and staff monitoring.</p>
                <Link to="/Healthcare" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 4 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Education} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Education</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Maintaining a secure environment for students and staff, managing access control.

How We Help: Provide easy-to-use tools for tracking incidents, gate passes, and training modules for campus security staff.</p>
                <Link to="/Education" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 5 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Retail} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Retail & Shopping Complexes</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Preventing theft, managing customer safety, and ensuring smooth daily operations.

How We Help:  Enable robust monitoring of staff and visitors, handle parcel logs, and manage reports effectively.</p>
                <Link to="/Residential" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 6 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Manufacture} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Government & Public Sector</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Protecting public assets, handling large-scale operations, and ensuring data security.

How We Help: Equip teams with robust reporting systems, emergency management tools, and comprehensive training solutions</p>
                <Link to="/Government" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 7 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Goverenment} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Residential Complexes & Gated Communities</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Ensuring the safety of residents, managing visitor access, and tracking incidents.

How We Help: Simplify gate pass issuance, staff monitoring, and compliance tracking for community safety</p>
                <Link to="/Residential" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 8 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Gated} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Logistics & Transportation</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Securing assets in transit, managing compliance, and monitoring personnel.

How We Help: Facilitate incident reporting, key management, and efficient training for staff on the move..</p>
                <Link to="/Logistics" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/* Repeat Card */}
        {/* Card 9 */}
        <div class="max-w-sm p-1 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Logistics} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Manufacturing & Warehousing</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges:  Ensuring the safety of goods, managing worker security, and complying with safety standards.

How We Help:   Facilitate first aid kit tracking, incident investigation, and compliance management.</p>
                <Link to="/Manufacturing" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#000000] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-[#000000] dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        </div>
        </div>
        <Form/>
    </div>

  )
}

export default Service