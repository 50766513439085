// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form {
    background-color: olive!; /* Default background */
  }
  
  @media screen and (min-width: 600px) {
    .Form {
      background-color: orange;
    }
  }
  
  @media screen and (min-width: 900px) {
    .Form {
      background-color: red;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,uBAAuB;EACnD;;EAEA;IACE;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE;MACE,qBAAqB;IACvB;EACF","sourcesContent":[".Form {\n    background-color: olive!; /* Default background */\n  }\n  \n  @media screen and (min-width: 600px) {\n    .Form {\n      background-color: orange;\n    }\n  }\n  \n  @media screen and (min-width: 900px) {\n    .Form {\n      background-color: red;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
