import React from 'react'

function Form() {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-2 xl:grid-cols-2 gap-14 p-4 '>
        <form className='mt-16'>
            {/* Name input */}
            <div className="mb-4">
              <label
                className="block text-2xl font-bold text-gray-700 mb-4 "
              >
                Find Us
              </label>
           <label className='mt-1 block w-full rounded-md text-start p-4 text-[#27335f] font-bold  shadow-sm sm:text-xl dark:bg-gray-700 dark:border-gray-600 dark:text-white bg-slate-100'>
Call Us
<p className='text-start text-[#27335f] text-sm pt-1 font-light'>+60 11-7030 7031</p>
           </label>
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
        
              </label>
              <label className='mt-1 block w-full rounded-md text-start p-4 text-[#27335f] font-bold  shadow-sm sm:text-xl dark:bg-gray-700 dark:border-gray-600 dark:text-white bg-slate-100'>
Email Now
<p className='text-start text-[#27335f] text-sm pt-1 font-light'>avessecurity@skieztechnologies.com</p>
           </label>
            </div>
            <div className="mb-4">
              <label
                htmlFor=""
                className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              </label>
              <label className='mt-1 block w-full rounded-md text-start p-4 text-[#27335f] font-bold  shadow-sm sm:text-xl dark:bg-gray-700 dark:border-gray-600 dark:text-white bg-slate-100'>
Address
<p className='text-start text-[#27335f] text-sm pt-1 font-light'>6374829369</p>
           </label>
            </div>
          </form>
     <div className="mt-10 block w-full mx-auto rounded-lg bg-white p-6 shadow-md">
          <form>
            {/* Name input */}
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Name
              </label>
              <input
                type="Name"
                id="Name"
                className="mt-1 block w-full rounded-md border-gray-300  focus:border-blue-500 focus:ring-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholder="Enter your Name"
                required
              />
            </div>
    
            {/* Email input */}
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full rounded-md border-gray-300  focus:border-blue-500 focus:ring-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholder="Enter your email"
                required
              />
            </div>
    
            {/* Message textarea */}
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Message
              </label>
              <textarea
                id="message"
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholder="Type your message"
                required
              ></textarea>
            </div>
            {/* Submit button */}
            <button
              type="submit"
              className="w-full rounded-md bg-[#27335f] px-4 py-2 text-white text-sm font-medium shadow hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-600"
            >
              Send
            </button>
          </form>
        </div>
        </div>
  )
}

export default Form