import React from 'react'
import Security from '../assets/images/Police.png'
import Form from '../components/Form'

function About() {
  return (
<section className="bg-white py-8 px-1 md:px-8 lg:px-12">
  <div className="container mx-auto flex flex-col lg:flex-row lg:items-center">
    <div className="lg:w-1/2 mb-6 lg:mb-0 text-center lg:text-left  lg:ml-[-110px] xl:ml-[10px]">
      <h1 className="text-[#27335f] font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-4">
        Aves
      </h1>
      <h2 className="text-lg md:text-xl lg:text-2xl font-semibold mb-3">
        About Us
      </h2>
      <p className="text-sm md:text-base lg:text-lg text-gray-700 max-w-prose">
        At AVES Security Management, we focus on using technology to improve and
        simplify security operations. Our goal is to provide organizations with
        tools that ensure safety, accountability, and efficiency.
      </p>
      <p className="text-sm md:text-base lg:text-lg text-gray-700 max-w-prose">
      To revolutionize security management by introducing innovative solutions, making the world safer and more efficient
      </p>
      <p className='bg-[#27335f] text-white font-semibold  rounded-full w-36 h-10 text-center mt-4 mb-4  pt-2  '>
        Get Quote
      </p>
    </div>
    <div className="lg:w-1/2 flex justify-center lg:justify-end lg:p-8 lg:ml-36">
      <img
        src={Security}
        alt="Security"
        className="w-full max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl rounded-md shadow-md"
      />
    </div>
  </div>
  <Form/>
</section>


  )
}

export default About