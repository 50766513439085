import React from 'react';
import illustrationIntro from '../assets/Avestab.png';
import Form from '../components/Form';
import Inovation from '../assets/Inovation.png'
import Intergrity from '../assets/Integrity.png'
import Excellence from '../assets/Excellence.png'
import playstore from '../assets/Playstore.png'
import appstore from '../assets/AppStrore.png'
import MobileAves from '../assets/MobileAves.png'
import MobileAvesnotify from '../assets/MobileAvesNotify.png'

function About() {
  return (
    <section id="hero">
      {/* Hero Section */}
      <div className="bg-[#000D16]">
        <div className="flex flex-col lg:flex-row-reverse items-center justify-center lg:justify-between gap-6 sm:gap-10 lg:gap-14 px-4 sm:px-6 lg:px-20">
          {/* Image Section */}
          <div className="flex justify-center lg:justify-end w-full lg:w-1/2 relative left-20">
            <img
              src={illustrationIntro}
              alt="Security Illustration"
              className="max-w-full  h-full rounded-lg lg:max-h-[600px]"
            />
          </div>

          {/* Text Section */}
          <div className="text-center lg:text-left w-full lg:w-1/2 pb-10 pt-10">
            <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-semibold leading-tight">
              AVES SECURITY <br /> MANAGEMENT SYSTEM
            </h1>
            <p className="text-slate-300 text-base sm:text-lg mt-4">
              Empowering Security Operations for a Safer Tomorrow
            </p>
            <p className="text-slate-300 text-sm sm:text-base lg:text-lg mt-4 font-serif">
              AVES Security Management brings everything you need into one app. Whether it's managing teams, tracking incidents, ensuring compliance, or monitoring performance, we cover all aspects of security operations in a single, easy-to-use platform.
            </p>
            <button className="bg-[#FABC3F] w-32 sm:w-36 lg:w-40 h-11 rounded-2xl mt-6 text-sm sm:text-base font-bold text-gray-900 hover:bg-yellow-500">
              Learn More
            </button>
          </div>
        </div>
      </div>

      {/* Comprehensive Security Section */}
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-20">
        <h1 className="text-center mt-10 text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-700">
          Comprehensive Security at Your Fingertips
        </h1>
        <p className='p-5 text-xl text-gray-600'>
        AVES Security Management goes beyond traditional security practices by offering a full range of solutions in one easy-to-use platform. Whether it's overseeing security teams, handling incidents, ensuring regulatory compliance, or monitoring key performance indicators, AVES brings all aspects of security management together to simplify your operations and keep your organization protected."All-in-One Security Solution
        </p>
      </div>
{/* Container with two grids */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-10">
  {/* Integrity */}
  <span className="shadow-lg shadow-gray-200 rounded-lg p-5 text-center">
    <img src={Inovation} alt="Integrity" className="bg-[#FF6767] w-16 h-16 mx-auto block rounded-full" />
    <h1 className="text-xl font-extrabold text-gray-800 pb-5 pt-4">Integrity</h1>
    <p>We earn trust by being transparent and dependable</p>
  </span>

  {/* Innovation */}
  <span className="shadow-lg shadow-gray-200 rounded-lg p-5 text-center">
    <img src={Intergrity} alt="Innovation" className="bg-[#88D150] w-16 h-16 mx-auto block rounded-full" />
    <h1 className="text-xl font-extrabold text-gray-800 pb-5 pt-4">Innovation</h1>
    <p className="pb-5">We keep evolving to address modern security challenges</p>
  </span>
</div>

{/* Container with single grid */}
<div className="shadow-lg shadow-gray-300 rounded-lg m-10 md:m-20 text-center p-6">
  <span>
    <img src={Intergrity} alt="Excellence" className="bg-[#4490E9] w-16 h-16 mx-auto block rounded-full" />
    <h1 className="text-xl font-extrabold text-gray-800 pb-5 pt-4">Excellence</h1>
    <p className="pb-4">
      We are dedicated to delivering top-quality solutions in everything we do.
    </p>
  </span>
</div>

            {/* Why choose us*/}
            <div className='bg-[#2E2D2D] pb-10 p-24'>
              <section className=' text-center'>
              <span className='pt-10'>
    <h1 className='text-2xl font-extrabold text-white pb-5 pt-5'>
    Why Choose US?
    </h1>
  <p className=' p-10 text-lg text-gray-200'>
  Our platform connects traditional security methods with modern needs, offering unmatched reliability, security, and efficiency, all tailored to meet your specific requirements.
 </p>
 <button className="bg-[#FABC3F] w-32 sm:w-36 lg:w-40 h-11 rounded-2xl mt-6 text-sm sm:text-base font-bold text-gray-900 hover:bg-yellow-500">
              Learn More
            </button>
  </span>
  </section>
            </div>
 {/* Play Store Section */}
<div className="p-6 pt-10">
  <section className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
    {/* Text & Download Buttons */}
    <div>
      <h1 className="text-3xl font-bold mb-4">Download App Now</h1>
      <p className="text-gray-600 mb-6">
        "Whether you’re managing a small team or a large-scale operation, AVES Security Management offers plans tailored to fit your needs. Each plan is designed to provide the tools and features necessary to streamline your security processes while maintaining cost-effectiveness."
      </p>
      <div className="flex gap-4 justify-center sm:justify-start">
        <img src={playstore} alt="Google Play" className="w-32 h-auto" />
        <img src={appstore} alt="App Store" className="w-32 h-auto" />
      </div>
    </div>

    {/* Mobile Screens */}
    <div className="flex justify-center gap-6">
      <img src={MobileAves} alt="App Screen 1" className="w-40 h-auto mx-auto" />
      <img src={MobileAvesnotify} alt="App Screen 2" className="w-40 h-auto mx-auto" />
    </div>
  </section>

  {/* Innovation, Integrity, Excellence Cards */}
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
    {/* Innovation */}
    <span className="shadow-lg shadow-gray-200 rounded-lg p-6 bg-[#FFE4F7] text-center">
      <img src={Inovation} alt="Innovation" className="mx-auto w-16 h-16" />
      <h1 className="text-xl font-extrabold text-[#76482E] pt-4 pb-2">100+</h1>
      <p className="text-xl font-extrabold text-[#76482E] ">
    DOWNLOAD
      </p>
    </span>

    {/* Integrity */}
    <span className="shadow-lg shadow-gray-200 rounded-lg p-6 bg-[#CCFFCB] text-center">
      <img src={Intergrity} alt="Integrity" className="mx-auto w-16 h-16" />
      <h1 className="text-xl font-extrabold text-[#216320] pt-4 pb-2">500+</h1>
      <p className="text-xl font-extrabold text-[#216320] ">
    LIKE
      </p>
    </span>

    {/* Excellence */}
    <span className="shadow-lg shadow-gray-200 rounded-lg p-6 bg-[#FFD9C4] text-center">
      <img src={Excellence} alt="Excellence" className="mx-auto w-16 h-16" />
      <h1 className="text-xl font-extrabold text-[#4F2F47] pt-4 pb-2">1500+</h1>
      <p className="text-xl font-extrabold text-[#4F2F47] ">
      5 star Rating
      </p>
    </span>
  </div>
</div>
    </section>
  );
}

export default About;
