import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Andriod from '../assets/GOVERENMENT OFFICERS.png'
import And from '../assets/GOVERNMENT OFFICE POILICE.png'
import Ios from '../assets/GOVERNMENT EMPLOOYE.png'
import HowIt from './HowIt';
import SecurityMale from '../assets/GovernmentBanner.png'
import HospitaltyBanner from '../assets/GovernmentMain.png'
import { Check } from 'lucide-react';
import Restarant from '../assets/ADMINISTRATIVE OFFICE.png'
import Event from '../assets/COURTHOUSE.png'
import Resort from '../assets/PUBLICSECTOR.png'
import ConventionCenter from '../assets/GOVERNMENTAGIES.png'
import Tour from '../assets/GOVERNMENTAGIES.png'
function GoverenmentOfficer() {
  return (
    <section>
       <div className='bg-white'>
         <div>
           <img src={SecurityMale} alt="Security App" className="w-screen" />
           <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        GOVERNMENT OFFICER
  </h1>
  <h3 className='font-light'>
   SECURITY GOVERNANCE SYSTEM
  </h3>
        </span>
         </div>
         <div className='bg-[#F7F7F7]'>
         <div className="text-center p-5 text-gray-700 font-semibold leading-relaxed max-w-2xl mx-auto pt-9">
  <p className="mb-4">
    <span className="">AVES</span> is a Security Governance System that simplifies the management of security operations for government offices, administration offices, and public service buildings through an online platform.
  </p>
  <p className="mb-4">
    It provides structured documentation, incident monitoring, and compliance tracking, ensuring a safe and controlled environment.
  </p>
</div>

         <div className="bg-[#F7F7F7] p-6 md:p-12">
         {/* Header Section */}
         <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
        
WHY DO GOVERNMENT OFFICES HAVE TO IMPLEMENT AVES SECURITY GOVERNANCE SYSTEM?

         </h2>
         </div>
   
         {/* Content Section */}
         <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Andriod}
               alt="Security App"
               className="w-full max-w-md rounded-3xl shadow-xl"
             />
           </div>
   
           {/* Features Section */}
           <div className="space-y-6">
             {features.map((feature, index) => (
               <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                      <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
       <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
   </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>
         </div>
       </div>
       
       <div className="bg-[#F7F7F7] p-6 md:p-12">
       <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
       MAJOR CHARACTERISTICS OF OUR AVES RESIDENT SECURITY SOFTWARE SYSTEM
         </h2>
         {/* Content Section */}
         <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Features Section */}
           <div className="space-y-6">
             {feature.map((feature, index) => (
               <div key={index} className=" p-4 rounded-lg flex items-start gap-3">
                       <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
       <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
   </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>   
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Ios}
               alt="Security App"
               className="w-full max-w-md rounded-lg shadow-lg"
             />
           </div>
         </div>
       </div>
       <div>
       <h2 className='ext-2xl md:text-3xl font-bold text-black text-center mt-10 mb-8'>
       KEY FEATURES OF OUR GOVERNMENT OFFICE SECURITY SYSTEM

        </h2>
       <div className="grid md:grid-cols-2 gap-8 items-center">
       
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={And}
               alt="Security App"
               className="w-full max-w-lg rounded-3xl shadow-xl"
             />
           </div>
           {/* Features Section */}
           <div className="space-y-6">
             {fea.map((feature, index) => (
               <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
                      <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
       <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
   </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>
         </div>
        
       </div>
       </div>
       <div>
       </div>
       <div className="relative w-full">
         {/* Background Image */}
         <img
           src={HospitaltyBanner}
           alt="Hospitality Security"
           className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
         />
   
         {/* Text Overlay */}
         <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
           <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
           Get Started Today  
           </h2>
           <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
           Experience the best mobile app for security with Aves – a state of the art Security  Governance System for your corporate world.
           </p>
   
           {/* CTA Button */}
           <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
             Contact Us →
           </button>
         </div>
       </div>
       <div className="px-6 lg:px-20 py-10 bg-gray-100">
         {/* Title Section */}
         <div className="text-center mb-10">
           <h2 className="text-2xl font-bold uppercase text-gray-800">
             FOR WHOM WE ARE BUILDING
           </h2>
           <h3 className="text-2xl font-bold text-gray-800">
             OUR GOVERNMENT OFFICES  SECURITYSOFTWARE SYSTEM
           </h3>
         </div>
         
            {/* Icon Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">RESTAURANTS AND CAFÉS</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">EVENT VENUES</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">HOTELS AND RESORTS</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">CONVENTION CENTERS</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">TOURISM AND TRAVEL OPERATIONS</p>
  </div>
</div>   
       </div>
          {/* How It Works Section */}
       <div className='bg-white '>
          <div className="mt-16 text-center px-10 py-10 rounded-xl">
            <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
          
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
           {/* Step 1 */}
           <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
             <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
               1
             </div>
             <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
             <p className="mt-2 text-gray-600 text-sm">
             Join and control security operations in government offices remotely.


             </p>
           </div>
       
           {/* Step 2 */}
           <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
             <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
               2
             </div>
             <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
             <p className="mt-2 text-gray-600 text-sm">
             Configure visitor monitoring, security patrol schedules, and incident reporting workflows.

             </p>
           </div>
       
           {/* Step 3 */}
           <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
             <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
               3
             </div>
             <h3 className="mt-4 text-lg font-bold text-gray-700">Start Securing</h3>
             <p className="mt-2 text-gray-600 text-sm">
             Maintain organized security management on office safety, access control, and compliance monitoring.

             </p>
           </div>
         </div>
          </div>
          </div>
 
       </section>
     )
   }
   const features = [
     {
       title: "COMPLETE SECURITY SOLUTIONS FOR GOVERNMENT FACILITIES",
       description: "AVES provides a thorough package of security solutions tailored for government offices, aimed at improving and streamlining security operations."
     },
     {
       title: "INSTANT SURVEILLANCE",
       description: "Stay ahead with prompt alerts and real-time data, enabling proactive security management in government facilities."
     },
     {
       title: "FLEXIBLE AND EXPANDABLE",
       description: "Our Security Guard Management Platform is designed to adapt to the unique security needs of government entities, offering a customizable and scalable framework."
     },
     {
       title: "EASY TO USE AND UNDERSTAND",
       description: "AVES' Government Security Solutions integrate smoothly with current security systems, ensuring a straightforward and efficient user experience."
     },
     {
       title: "INSIGHT-DRIVEN SECURITY APPROACHES",
       description: "Leverage valuable insights and analytics to strengthen security measures across your government properties."
     }
   ];
   
   const feature = [
     {
       title: "VISITOR & CONTRACTOR ACCESS GOVERNANCE",
       description: "Maintain digital records of all officials, government contractors, and visitors. Tracksecurity clearance approvals and the issuing of access permits.",
     },
     {
       title: " SECURITY STAFF DEPLOYMENT & PATROL TRACKING",
       description: "Control security staff assignments, duty rosters, and surveillance activities.Maintain orderly control of security patrols and shift changes."
     },
     {
       title: "EMERGENCY RESPONSE & COMPLIANCE MONITORING",
       description: "Control crisis response procedures, evacuation procedures, and safety drills.Track security policy compliance, audits, and risk assessments"
     },
     {
       title: "SENSITIVE DOCUMENT & ASSET SECURITY GOVERNANCE",
       description: "Maintain records of significant issuances, restricted access approvals, and security audits.Exercise control over classified documents, equipment, and office property."
     }
   ]

   const fea = [
    {
      title: "VISITOR & STAFF ACCESS TRACKING",
      description: "Maintain digital records of all visitors, staff, and authorized personnel.Exercise issuing temporary access permit and security passes",
    },
    {
      title: "INCIDENT REPORTING & SECURITY VIOLATION MONITORING",
      description: "Electronically record security incidents, workplace disruptions, and unauthorized access attempt"
    },
    {
      title: "RESTRICTED AREA & CLASSIFIED DOCUMENT SECURITY GOVERNANCE",
      description: "Manage access control logs for the restricted office areas, archives, and server rooms.Maintain organized monitoring of confidential security clearances"
    },
    {
      title: "EMERGENCY RESPONSE & RISK MITIGATION",
      description: "Coordinate emergency drills, security response coordination, and evacuation plans.Maintain well-organized records of government security policies, compliance audits, and safety training sessions"
    },
    {
      title: "ENHANCE YOUR GOVERNMENT SECURITY GOVERNANCE",
      description: "AVES offers organized governance of security operations in government offices with electronic control of access control, security patrols, and incident reporting. Our solution enhances office security administration, operational efficiency, and regulatory compliance."
    }
  ]
  
   
   const Step = ({ number, title, description }) => {
     return (
       <div className="flex  items-center gap-4">
         <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
           {number}
         </div>
         <div>
           <h3 className="font-bold text-lg">{title}</h3>
           <p className="text-gray-600 text-sm">{description}</p>
         </div>
         
       </div>
    
  )
}

export default GoverenmentOfficer