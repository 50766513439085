import React from 'react';
import SecurityOfficer from '../assets/images/Police.png';
import SecurityGuard from '../assets/SecuirtyGaurd.png';
import Form from '../components/Form';
import tick from '../assets/Tick.png'

function About() {
  return (
    <section className="px-3 md:px-8 lg:px-14 xl:px-10 py-12 bg-white mr-32 ">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        <div className='pr-10'>
          <section className=''></section>
          <h1 className="text-blue-600 font-semibold">AVES</h1>
          <h2 className="text-4xl font-bold mt-2">ABOUT US</h2>
          <p className="mt-4 text-gray-600">
            At AVES Security Management, we focus on using technology to improve and simplify security operations.
            Our goal is to provide organizations with tools that ensure safety, accountability, and efficiency.
          </p>
          <p className="mt-4 text-gray-600">
            We revolutionize security management by introducing innovative solutions, making the world safer and more efficient.
          </p>
          <button className="mt-6 px-6 py-3 bg-[#000000] text-white font-semibold rounded-md hover:bg-blue-700">
            Get a Quote
          </button>
        </div>
        <div className="relative">
          <img src={SecurityOfficer} alt="Security Officer" className="rounded-lg shadow-lg w-full" />
          <img src ={SecurityGuard} className='absolute top-96 right-0 ml-10 xl:mt-16 lg:mt-20 rounded-md'></img>
        </div>
      </div>
      
      {/* Second Section */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 items-center mt-20">
        <div>
          <h2 className="text-4xl font-bold">Comprehensive Security at Your Fingertips</h2>
          <p className="mt-4 text-gray-600">
            AVES Security Management goes beyond traditional security apps by offering a full range of solutions in one easy-to-use platform.
            Whether it's overseeing security teams, handling incidents, ensuring regulatory compliance, or monitoring key performance indicators,
            AVES brings all aspects of security management together to simplify your operations and keep your organization protected.
          </p>
          {/* Feature List */}
          <div className='mt-10 flex items-center'>
  <img src={tick} alt="Tick Icon" className="mr-2" />
  <span className='p-5'>
    <p className='text-3xl font-medium'>
Inovation
    </p><p>
    We keep evolving to address modern security challenges.
    </p>
  </span>
</div>
<div className='mt-10 flex items-center'>
  <img src={tick} alt="Tick Icon" className="mr-2" />
  <span className='p-5'>
  <p className='text-3xl font-medium'>
Inovation
    </p><p>
    We keep evolving to address modern security challenges.
    </p>
  </span>
</div>
<div className='mt-10 flex items-center'>
  <img src={tick} alt="Tick Icon" className="mr-2" />
  <span className='p-5'>
  <p className='text-3xl  font-medium'>
Inovation
    </p><p>
    We keep evolving to address modern security challenges.
    </p>
  </span>
</div>
        </div>
      </div>
      <div className="container mx-auto px-6 md:px-12 lg:px-24 text-center mt-16">
        <h2 className="text-3xl font-bold text-gray-900 text-center relative left-16">
          Bridging Traditional Security with Modern Innovation
        </h2>
        <p className="mt-4 text-gray-600 relative left-16">
          Our platform connects traditional security methods with modern models,
          offering unmatched reliability, security, and efficiency. All tailored
          to meet your specific requirements.
        </p>
      </div>
      <Form></Form>
    </section>
  );
}

export default About;
