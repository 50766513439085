import React from 'react'

function Plans() {
  return (
<div className="">
  <div className='bg-[#27335f]'>
  <h1 className='text-xl text-white flex justify-center items-center pt-10 font-bold lg:text-5xl xl:text-6xl'>
  Flexible Plans for Every Organization
    </h1>
    <div>
      <p className='text-white flex justify-center text-center items-center p-10 xl:p-20'>
      Whether you’re managing a small team or a large-scale operation, AVES Security Management offers plans tailored to fit your needs. Each plan is designed to provide the tools and features necessary to streamline your security processes while maintaining cost-effectiveness
      </p>
    </div>
    <section className="">
  <div className="sm:flex sm:flex-col sm:align-center p-10">
    <div className="relative self-center bg-slate-200 rounded-lg p-0.5 flex">
      <button
        type="button"
        className="relative w-1/3 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8 bg-slate-50 border-slate-50 text-slate-900 shadow-sm cursor-pointer hover:bg-[#27335f] hover:text-white"
      >
        Monthly billing
      </button>
      <button
        type="button"
        className="ml-0.5 relative w-1/2 border rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8 border-transparent text-slate-900 cursor-pointer hover:bg-[#27335f] hover:text-white"
      >
        Yearly billing
      </button>
    </div>
    <div className="mt-12 space-y-3 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xl:max-w-4xl xl:mx-auto xl:px-6">
      <div className="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200 bg-white xl:max-w-[300px] lg:max-w-[300px] mx-auto">
        <div className="p-6">
          <h2 className="text-xl leading-6 font-bold text-slate-900">Package A</h2>
          <p className="mt-2 text-base text-slate-700 leading-tight">
            For smaller teams seeking to digitize their operations with essential features like basic reporting and personnel management
          </p>
          <p className="mt-8">
            <span className="text-4xl font-bold text-slate-900 tracking-tighter">$2.99</span>
            <span className="text-base font-medium text-slate-500">/mo</span>
          </p>
          <a
            href="/sign-up"
            className="mt-8 block w-full bg-slate-900 rounded-md py-2 text-sm font-semibold text-white text-center"
          >
            Join as a Starter
          </a>
        </div>
        <div className="pt-6 pb-8 px-6">
          <h3 className="text-sm font-bold text-slate-900 tracking-wide uppercase">What's included</h3>
          <ul role="list" className="mt-4 space-y-3">
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">1 landing page included</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200 bg-white xl:lg:max-w-[300px] lg:max-w-[300px] mx-auto">
        <div className="p-6">
          <h2 className="text-xl leading-6 font-bold text-slate-900">Package B</h2>
          <p className="mt-2 text-base text-slate-700 leading-tight">
            A complete solution for large organizations, offering full-featured capabilities, priority support, and bespoke configurations
          </p>
          <p className="mt-8">
            <span className="text-4xl font-bold text-slate-900 tracking-tighter">$3.99</span>
            <span className="text-base font-medium text-slate-500">/mo</span>
          </p>
          <a
            href="/sign-up"
            className="mt-8 block w-full bg-slate-900 rounded-md py-2 text-sm font-semibold text-white text-center"
          >
            Join as a Superior
          </a>
        </div>
        <div className="pt-6 pb-8 px-6">
          <h3 className="text-sm font-bold text-slate-900 tracking-wide uppercase">What's included</h3>
          <ul role="list" className="mt-4 space-y-3">
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
          </ul>
        </div>
      </div>
      {/*Card 3 */}
      
      <div className="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200 bg-white xl:lg:max-w-[300px] lg:max-w-[300px] mx-auto">
        <div className="p-6">
          <h2 className="text-xl leading-6 font-bold text-slate-900">Package C</h2>
          <p className="mt-2 text-base text-slate-700 leading-tight">
            A complete solution for large organizations, offering full-featured capabilities, priority support, and bespoke configurations
          </p>
          <p className="mt-8">
            <span className="text-4xl font-bold text-slate-900 tracking-tighter">$3.99</span>
            <span className="text-base font-medium text-slate-500">/mo</span>
          </p>
          <a
            href="/sign-up"
            className="mt-8 block w-full bg-slate-900 rounded-md py-2 text-sm font-semibold text-white text-center"
          >
            Join as a Superior
          </a>
        </div>
        <div className="pt-6 pb-8 px-6">
          <h3 className="text-sm font-bold text-slate-900 tracking-wide uppercase">What's included</h3>
          <ul role="list" className="mt-4 space-y-3">
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
          </ul>
        </div>
      </div>
      {/*card 4*/}
      <div className="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200 bg-white xl:lg:max-w-[300px] lg:max-w-[300px] mx-auto">
        <div className="p-6">
          <h2 className="text-xl leading-6 font-bold text-slate-900">Package C</h2>
          <p className="mt-2 text-base text-slate-700 leading-tight">
            A complete solution for large organizations, offering full-featured capabilities, priority support, and bespoke configurations
          </p>
          <p className="mt-8">
            <span className="text-4xl font-bold text-slate-900 tracking-tighter">$3.99</span>
            <span className="text-base font-medium text-slate-500">/mo</span>
          </p>
          <a
            href="/sign-up"
            className="mt-8 block w-full bg-slate-900 rounded-md py-2 text-sm font-semibold text-white text-center"
          >
            Join as a Superior
          </a>
        </div>
        <div className="pt-6 pb-8 px-6">
          <h3 className="text-sm font-bold text-slate-900 tracking-wide uppercase">What's included</h3>
          <ul role="list" className="mt-4 space-y-3">
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
            <li className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 h-5 w-5 text-green-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <span className="text-base text-slate-700">All Free features</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

  </div>
</div>

  )
}

export default Plans