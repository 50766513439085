import { useState } from "react";
import { Link } from "react-router-dom";
import Aves from "../assets/AvesOriginal.webp";

const Navbar = ({formRef}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleScrolltoform = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className="bg-white shadow-md">
      <nav className="relative container mx-auto p-4 lg:px-10">
        {/* Flex Container */}
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center justify-center md:justify-start">
  <Link to="/">  
    <img
      src={Aves}
      alt="Aves Logo"
      className="h-32 w-auto cursor-pointer transition duration-500 hover:scale-110"
    />
  </Link>
</div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6 items-center">
            <Link
              to="/"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              About
            </Link>
            <Link
              to="/service"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Services
            </Link>

            {/* Resources Dropdown 
            <div className="relative">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300 focus:outline-none"
              >
                Resources 
              </button>
              {dropdownOpen && (
                <div className="absolute left-[-300px] mt-5 w-[500px] h-52  bg-white shadow-2xl rounded-lg py-2">
                  <Link
                    to="/blog"
                    className="block  pt-14 text-gray-700 text-start  hover:bg-gray-100"
                  >
                    Blog
                  </Link>
                  <Link
                    to="/case-study"
                    className="block   text-gray-700 text-end hover:bg-gray-100"
                  >
                    Case Study
                  </Link>
                </div>
              )}
            </div>*/}
          </div>

          {/* Contact Button */}
          <button
            onClick={handleScrolltoform}
            className="hidden md:block p-3 px-6 text-white bg-[#000000] font-semibold rounded-full hover:bg-gray-800 transition duration-300"
          >
            Contact Us
          </button>

          {/* Hamburger Icon for Mobile Menu */}
          <button
            className="block md:hidden focus:outline-none"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <div className="space-y-2">
              <span className="block h-1 w-8 bg-gray-700"></span>
              <span className="block h-1 w-8 bg-gray-700"></span>
              <span className="block h-1 w-8 bg-gray-700"></span>
            </div>
          </button>
        </div>

        {/* Mobile Menu */}
        {toggleMenu && (
          <div className="md:hidden mt-6 flex flex-col items-center space-y-4 bg-white p-6 rounded-lg shadow-lg">
            <Link
              to="/"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              About
            </Link>
            <Link
              to="/service"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Services
            </Link>

            {/* Resources Dropdown in Mobile Menu */}
            <div className="relative w-full text-center">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300 w-full"
              >
                Resources 
              </button>
              {dropdownOpen && (
                <div className="mt-2 w-full h-96 bg-white shadow-lg rounded-lg py-2">
                  <Link
                    to="/blog"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Blog
                  </Link>
                  <Link
                    to="/case-study"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Case Study
                  </Link>
                </div>
              )}
            </div>

            <Link
              to="/contact"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Contact
            </Link>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
