import { useState } from 'react';
import { Link } from 'react-router-dom';

import companyLogo from '../assets/images/logo.svg';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='bg-[#27335f] p-3 w-full lg:w-full xl:w-full'>
    <nav className='relative container mx-auto p-6X'>
      {/* Flex Container */}
      <div className='flex items-center justify-between'>
        {/* Logo */}
        <div className='pt-2'>
          <p className='text-2xl font-bold text-white cursor-pointer transition duration-500 hover:scale-110 lg:flex lg:pr-72 xl:pr-[650px] lg:relative right-0 xl:pl-20 '>Aves Security</p>
        </div>
        {/* Menu Items */}
        <div className='hidden space-x-6 md:flex '>
          <Link to='/' className='text-white font-bold hover:text-darkGrayishBlue '>
            Home
          </Link>
          <Link to='/about' className='text-white font-bold hover:text-darkGrayishBlue '>
            About
          </Link>
          <Link to='/service' className='text-white font-bold hover:text-darkGrayishBlue '>
            Service
          </Link>
          <Link to='/plans' className='text-white font-bold hover:text-darkGrayishBlue '>
            Plans 
          </Link>
          
        </div>
        {/* Button */}
        <Link
          to='#'
          className='hidden p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight  md:block'
        >
          Get Started
        </Link>

        {/* Hamburger Icon */}
        <button
          className={
            toggleMenu
              ? 'open block hamburger md:hidden focus:outline-none'
              : 'block hamburger md:hidden focus:outline-none'
          }
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span className='hamburger-top'></span>
          <span className='hamburger-middle'></span>
          <span className='hamburger-bottom'></span>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className='md:hidden'>
        <div
          className={
            toggleMenu
              ? 'absolute flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
              : 'absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
          }
        >
          <Link to='/'>Home</Link>
          <Link to='/about'>About</Link>
          <Link to='/Contact'>Contact</Link>
          <Link to='/service'>Service</Link>
          <Link to='/plans'>Plans</Link>

        </div>
      </div>
    </nav>
    </div>
  );
};

export default Navbar;
