import React from 'react'
import Form from './Form'
import Andriod from '../assets/HospitalitySecurity.png'
import Ios from '../assets/HospitalityOccurence.png'
import HowIt from './HowIt';
import { Check } from 'lucide-react';
import SecurityMale from '../assets/SecurityCaller.png'
import HospitaltyBanner from '../assets/Hospitalitymain.png'
import Restarant from '../assets/Restatrant.png'
import Event from '../assets/Eventicon.png'
import Resort from '../assets/Resort.png'
import ConventionCenter from '../assets/ConventionCentre.png'
import Tour from '../assets/Tourisim.png'

function Hospitality() {
  return (
    <section>
    <div className='bg-white'>
      <div>
        <img src={SecurityMale} className='w-screen' alt="Security App"></img>
        <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        HOSPITALITY
  </h1>
  <h3 className='font-light'>
  SECURITY MANAGEMENT SYSTEM
  </h3>
        </span>
      
      </div>
      <div className='bg-[#F7F7F7]'>
      <div className="text-center pt-10 p-5 text-gray-500 font-semibold space-y-2 max-w-2xl mx-auto leading-relaxed">
  <p>AVES is a comprehensive Security Governance System tailored to offer organized oversight, compliance, and operational management for hospitality enterprises.</p>
  <p>From hotels to resorts and event venues to convention centers, AVES facilitates the management of incident reporting, governance, and access control through a digital platform.</p>
  <p>AVES allows hospitality organizations to manage their security operations effectively, ensuring detailed records, approvals, and compliance documentation that foster efficient coordination and accountability.</p>
</div>

      <div className="bg-[#F7F7F7] p-6 md:p-12">
      {/* Header Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
      WHY SHOULD  YOU USE AVES  HOSPITALITY SECURITY GOVERNANCE SYSTEM 
      </h2>
      </div>

      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Andriod}
            alt="Security App"
            className="w-full max-w-lg rounded-3xl shadow-xl"
          />
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                   <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
    <Check className='w-4 h-4 relative bottom-[7px] right-[8px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="bg-white p-6 md:p-12">
    <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
    MAJOR CHARACTERISTICS OF OUR AVES HOSPITALITY SECURITY SOFTWARE SYSTEM
      </h2>
      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Features Section */}
        <div className="space-y-6">
          {feature.map((feature, index) => (
            <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
                    <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
    <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Ios}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
    </div>
    <div>
    </div>
    <div className="relative w-full">
      {/* Background Image */}
      <img
        src={HospitaltyBanner}
        alt="Hospitality Security"
        className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
      />

      {/* Text Overlay */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
        <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
        Get Started Today
        </h2>
        <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
        Enhance security, streamline operations, and ensure compliance with our advanced governance system. Take control of your security protocols with efficiency and precision.
        </p>

        {/* CTA Button */}
        <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
          Contact Us →
        </button>
      </div>
    </div>
    <div className="px-6 lg:px-20 py-10 bg-gray-100">
      {/* Title Section */}
      <div className="text-center mb-10">
        <h2 className="text-2xl font-bold uppercase text-gray-800">
          FOR WHOM WE ARE BUILDING
        </h2>
        <h2 className="text-2xl font-bold text-gray-800">
          OUR HOSPITALITY SECURITY SYSTEM
        </h2>
      </div>
      
      {/* Icon Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">RESTAURANTS AND CAFÉS</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">EVENT VENUES</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">HOTELS AND RESORTS</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">CONVENTION CENTERS</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">TOURISM AND TRAVEL OPERATIONS</p>
  </div>
</div>
    </div>
    {/* How It Works Section */}
<div className='bg-white '>
           <div className="mt-16 text-center px-10 py-10 rounded-xl">
             <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
           
             <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {/* Step 1 */}
            <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
              <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
                1
              </div>
              <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
              <p className="mt-2 text-gray-600 text-sm">
              Businesses operating in hospitality need to register for digital management of security operations. 

              </p>
            </div>
        
            {/* Step 2 */}
            <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
              <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
                2
              </div>
              <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
              <p className="mt-2 text-gray-600 text-sm">
              Create workflows for reporting incidents, as well as establish visitor log systems and track issued keys in the platform.
              </p>
            </div>
        
            {/* Step 3 */}
            <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
              <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
                3
              </div>
              <h3 className="mt-4 text-lg font-bold text-gray-700">Start Governing Security Operations </h3>
              <p className="mt-2 text-gray-600 text-sm">
              The system should keep digital records organized, and it should also automate emergency escalation processes while upholding regulatory requirements. 
              </p>
            </div>
          </div>
           </div>
           </div>
   
    </section>
  )
}
const features = [
  {
    title: "COMPREHENSIVE SECURITY SOLUTIONS FOR THE HOSPITALITY INDUSTRY",
    description: "AVES delivers an all-encompassing Hospitality Security Software solution aimed at refining and enhancing your organization's security processes"
  },
  {
    title: "IMMEDIATE SURVEILLANCE",
    description: "Gain a competitive advantage with prompt notifications and real-time information, enabling proactive security management in hospitality environments",

  },
  {
    title: "TAILORED AND SCALABLE",
    description: "The Hospitality Management Software is designed to adapt to your organization's specific needs, providing a flexible and scalable security framework"
  },
  {
    title: "USER-FRIENDLY AND INTUITIVE ",
    description: "Our Hospital Security Guards Management Platform is engineered for straightforward integration, minimizing implementation time through an easy-to-use interface.",

  },
  {
    title: "INSIGHT-DRIVEN SECURITY STRATEGIES",
    description: "Employ actionable insights and analytics to bolster security protocols throughout your hospitality establishment.",
    
  }
];

const feature = [
  {
    title: "COMPALINCE & ACCOUNTABILITY",
    description: "Ensures comprehensive documentation in every security report.",
    descriptions: "Maintain well-organized digital records for incident reporting, patrol management, and security evaluations."
  },
  {
    title: "INCIDENT MANAGEMENT & RESPONSE",
    description: "Record, track, and escalate security incidents, lost property cases, and emergency situation in the digital format.",
    descriptions: "Streamline the approval flow process and accountability for critical security actions through automation."
  },
  {
    title: "VISITOR AND CONTRACTOR MANAGEMENT ",
    description: "Improve the check-in procedure for visitors, contractors, and vendors by implementing digital record-keeping.",
    descriptions: "Ensure real-time tracking of arrivals and departures to reduce the risk of unauthorized access."
  },
  {
    title: "ACCESS AND KEY MANAGEMENT ",
    description: "Receive prompt notification for any event such as accident, crisis or suspicious event.",
    descriptions: "Track access to restricted areas based on key user data in the past"
  },
  {
    title: "ADMINISTRATION OF GATE PASSES AND DELIVERIES",
    description: "Implementing digital systems to effectively monitor and manage gate passes for vendors, suppliers, and personnel.",
    descriptions: "Ensure complete records are maintained for all deliveries, regardless of whether they are incoming or outgoing."
  }
]

const Step = ({ number, title, description }) => {
  return (
    <div className="flex  items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
      
    </div>
  )
}

export default Hospitality