import React from 'react';
import Ac from '../assets/images/AC.png';
import Am from '../assets/images/Am.jpg';
import Form from './Form';
import Andriod from '../assets/AuxilarySecurityGaurd.png';
import Ios from '../assets/AuxilarySecurityGaurdPolice.png';
import HowIt from './HowIt';
import { Check } from 'lucide-react';
import Auxilary from '../assets/Auxilarycaller.png'
import SecurityMale from '../assets/AuxilaryBanner.png'
import HospitaltyBanner from '../assets/AuxilaryMain.png'
import Restarant from '../assets/AIRPORT POLICE.png'
import Event from '../assets/RAILWAY POLICE.png'
import Resort from '../assets/TRAFIC POLICE.png'
import ConventionCenter from '../assets/INDUSTRIAL POLICE.png'
import Tour from '../assets/CAMPOUS POLICE.png'



function AuxilaryPolice() {
  return (
    <section>
    <div className='bg-white'>
      <div>
        <img src={SecurityMale} alt="Security App" className="w-screen" />
        <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        AUXILIARY POLICE
  </h1>
  <h3 className='font-light pt-3y'>
  SECURITY MANAGEMENT GOVERNENCE SYSTEM
  </h3>
        </span>
      </div>
      <div className='bg-[#F7F7F7]'>
      <div className="bg-[#F7F7F7] px-6 md:px-12 lg:px-20 py-10">
  <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-center leading-snug">
    WHY AUXILIARY POLICE AGENCIES SHOULD ADOPT THE AVES SECURITY GOVERNANCE SYSTEM?
  </h2>

  <div className="text-center text-gray-600 font-medium mt-6 space-y-3 max-w-3xl mx-auto">
    <p>
      AVES serves as a Security Governance System specifically designed to digitally oversee and manage 
      law enforcement operations for Auxiliary Police units.
    </p>
    <p>
      It allows for systematic documentation, real-time reporting, and compliance tracking, ensuring 
      effective incident management, patrol supervision, and regulatory compliance—without the need for extra hardware.
    </p>
    <p>
      By digitally governing security patrols, duty logs, and emergency response coordination, 
      AVES fosters greater transparency, accountability, and efficiency in Auxiliary Police operations.
    </p>
  </div>
</div>

      <div className="bg-[#F7F7F7] p-6 md:p-12">
      {/* Header Section */}
      <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
      WHY SHOULD SOMEONE DOWNLOAD AVES SECURITY GOVERNANCE SYSTEM?
      </h2>
      </div>

      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Andriod}
            alt="Security App"
            className="w-full max-w-lg rounded-3xl shadow-xl"
          />
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                   <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
    <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="bg-white p-6 md:p-12">
    <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
    MAJOR CHARACTERISTICS OF OUR AVES AUXILIARY SECURITY SOFTWARE SYSTEM?
      </h2>
      {/* Content Section */}
      <div className="grid md:grid-cols-2 gap-8 items-center">
        {/* Features Section */}
        <div className="space-y-6">
          {feature.map((feature, index) => (
            <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
                    <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
    <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
</span>
              <div>
                <h3 className="font-bold text-lg">{feature.title}</h3>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src={Ios}
            alt="Security App"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
    </div>
    <div>
    </div>
    <div className="relative w-full">
      {/* Background Image */}
      <img
        src={HospitaltyBanner}
        alt="Hospitality Security"
        className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
      />

      {/* Text Overlay */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
        <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
        Get Started Today  
        </h2>
        <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
        Experience the best mobile app for security with Aves – a state of the art Security  Governance System for your corporate world.
        </p>

        {/* CTA Button */}
        <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
          Contact Us →
        </button>
      </div>
    </div>
    <div className="px-6 lg:px-20 py-10 bg-gray-100">
      {/* Title Section */}
      <div className="text-center mb-10">
        <h2 className="text-2xl font-bold uppercase text-gray-800">
          FOR WHOM WE ARE BUILDING
        </h2>
        <h3 className="text-2xl font-bold text-gray-800">
          OUR AUXILIARY SECURITY SYSTEM
        </h3>
      </div>
      
          {/* Icon Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">AIRPORT POLICE</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">RAILWAY POLICE</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">TRAFFIC POLICE</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">INDUSTRIAL POLICE</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">CAMPUS POLICE</p>
  </div>
</div>
{/* How It Works Section */}
    </div>
     <div className='bg-white '>
              <div className="mt-16 text-center px-10 py-10 rounded-xl">
                <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
              
                <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
               {/* Step 1 */}
               <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
                   1
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With AVES</h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Sign up to organize law enforcement and security operations online.

                 </p>
               </div>
           
               {/* Step 2 */}
               <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
                   2
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Prep patrol reports, security reporting schedule, and emergency response reports.
                 </p>
               </div>
           
               {/* Step 3 */}
               <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
                 <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
                   3
                 </div>
                 <h3 className="mt-4 text-lg font-bold text-gray-700">Begin to Direct Security Operations</h3>
                 <p className="mt-2 text-gray-600 text-sm">
                 Enable effective patrol duty management, equipment deployment, and law enforcement records management.
                 </p>
               </div>
             </div>
              </div>
              </div>
  
    </section>
  )
}
const features = [
  {
    title: "INSTANT SURVEILLANCE",
    description: "Gain an advantage with prompt notifications and real-time data, allowing for proactive security management in auxiliary police functions."
  },
  {
    title: "FLEXIBLE AND SCALABLE",
    description: "The Police Auxiliary Application can be customized to meet your organization’s specific needs, offering a versatile and scalable security solution."
  },
  {
    title: "INTUITIVE AND ACCESSIBLE",
    description: "Our Auxiliary Police Security Management Platform is designed for straightforward integration, minimizing setup time with its user-friendly interface"
  },
  {
    title: "DATA-DRIVEN SECURITY ENHANCEMENTS",
    description: "Employ actionable insights and analytics to improve security strategies within your auxiliary police team."
  },
  {
    title: "SUPERIOR TRACKING AND OVERSIGHT",
    description: "With our Auxiliary Police Security App, you can effectively manage personnel, monitor activities, and ensure compliance with security protocols."
  }
];

const feature = [
  {
    title: "INCIDENT REPORTING & CASE MANAGEMENT",
    description: "Keep organized records of security incidents, disturbances, and investigations.Digitally document arrests and suspect information for compliance purposes.",
  },
  {
    title: "PATROL DUTY ADMINISTRATION",
    description: "Log patrol routes, assigned personnel, and shift schedules electronically.Mandate that officers report any suspicious activities and security observations immediately."
  },
  {
    title: "VEHICLE & EQUIPMENT TRACKING",
    description: "Manage the distribution and return of firearms, radios, and tactical equipment.Keep organized logs of patrol vehicle usage and maintenance timelines."
  },
  {
    title: " EMERGENCY RESPONSE MANAGEMENT",
    description: "Supervise incident response coordination for public disturbances, evacuations, and critical events.Maintain organized records of drills, training sessions, and crisis simulations"
  },
  {
    title: "SECURITY PASSES & AUTHORIZATION LOGS",
    description: "Manage access permissions for controlled and restricted areas.Keep organized logs of security clearances and special duty assignments."
  }
]

const Step = ({ number, title, description }) => {
  return (
    <div className="flex  items-center gap-4">
      <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
        {number}
      </div>
      <div>
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
      
    </div>
  );
};

export default AuxilaryPolice;
