import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';

const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const userId = searchParams.get("userId");
    if (userId) {
      axios
        .get(`http://dashboard.avessecurity.com/?userId=${userId}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch(() => {
          setError("Invalid or expired link.");
        });
    } else {
      setError("Invalid link.");
    }
  }, [searchParams]);

  if (error) {
    return <h2 style={{ textAlign: "center", marginTop: "50px" }}>{error}</h2>;
  }

  if (!userData) {
    return <h2 style={{ textAlign: "center", marginTop: "50px" }}>Loading...</h2>;
  }

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Dashboard</h1>
      <p>Email: {userData.email}</p>
      <p>User ID: {userData.userId}</p>
    </div>
  );
};

export default Dashboard;
