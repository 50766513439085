import React, { useState } from "react";
import axios from "axios";

const EmailSubmit = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.avessecurity.com/api/Qrcode/submit-email",
        { email }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || "An error occurred.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-lg mx-auto">
        <div
          className="relative h-40 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://example.com/background-image.jpg')`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center">
            <h1 className="text-white text-3xl font-semibold">
            </h1>
            <p className="text-white text-sm mt-2">
            </p>
          </div>
        </div>
        <div className="p-6">
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <div className="w-full flex items-center bg-gray-100 rounded-lg px-3 py-2 mb-4">
              <input
                type="email"
                placeholder="Enter your email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="flex-grow bg-transparent text-gray-700 outline-none"
              />
            </div>
            <button
              type="submit"
              className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition duration-200"
            >
              Submit
            </button>
          </form>
          {message && (
            <p className="mt-4 text-center text-gray-600 text-sm">{message}</p>
          )}
          <p className="text-gray-400 text-xs mt-4 text-center">
            Only one email a week. Unsubscribe anytime.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailSubmit;
