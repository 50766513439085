import Security from "../assets/FormSecurity.png";
import React, { forwardRef } from "react";
import "./App.css";

const Form = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="px-4 py-10 md:px-10 lg:px-16 xl:px-24 bg-[#F7F7F7] Form">
      {/* Title Section */}
      <div className="text-center mb-10">
        <h1 className="text-2xl font-bold md:text-3xl">Get in touch</h1>
        <p className="text-gray-600 mt-2">
          Discover how AVES Security Management can revolutionize your operations.
          <br />Contact Us for a personalized consultation.
        </p>
      </div>

      {/* Form and Image Section */}
      <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
        {/* Form Section */}
        <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full">
          <h2 className="text-xl font-bold">Security Operations for a Safer Tomorrow</h2>
          <p className="text-gray-600 text-sm mt-2 space-y-7">
           For Customized Plans & Features are Connect with Us
          </p>

          <form className="mt-5 space-y-4">
            {/* Name Inputs */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input type="text" placeholder="First Name" className="p-3 border rounded-md w-full" required />
              <input type="text" placeholder="Last Name" className="p-3 border rounded-md w-full" required />
            </div>
            {/* Email */}
            <input type="email" placeholder="Email" className="p-3 border rounded-md w-full" required />
            {/* Company Name */}
            <input type="text" placeholder="Company Name" className="p-3 border rounded-md w-full" required />
            {/* Message */}
            <textarea rows="4" placeholder="Message" className="p-3 border rounded-md w-full" required></textarea>
            {/* Submit Button */}
            <button type="submit" className="w-full bg-black text-white py-3 rounded-md font-medium hover:bg-gray-700">
              Submit
            </button>
          </form>
        </div>

        {/* Image Section */}
        <div className="relative hidden lg:block w-full">
          <img src={Security} alt="Security" className="rounded-lg shadow-xl w-full" />
          <p className="absolute bottom-4 left-4 text-white text-sm">
            Get analytical reports for your security management and security measures enhancement.
          </p>
        </div>
      </div>
    </div>
  );
});

export default Form;
