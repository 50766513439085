import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Mobile from '../assets/images/iPhone .png'
import Andriod from '../assets/EvenInspection.png'
import Androids from '../assets/EVENTGROUP.png'
import Ios from '../assets/EVENTPOLICERS.png'
import HowIt from './HowIt';
import { Check } from 'lucide-react';
import SecurityMale from '../assets/EventBanner.png'
import HospitaltyBanner from '../assets/EventMain.png'
import Restarant from '../assets/CONCERNFESTIVAL.png'
import Event from '../assets/CORPORATECONCERNS.png'
import Resort from '../assets/TRADESHOWS.png'
import ConventionCenter from '../assets/SPORTINGEVENT.png'
import Tour from '../assets/EXIPITIONPUBLIC.png'

function EventSecurity() {
  return (
  
     <section>
        <div className='bg-white'>
          <div>
            <img src={SecurityMale} alt="Security App" className="w-screen" />
            <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        EVENT
  </h1>
  <h3 className='font-light pt-2'>
  SECURITY MANAGEMENT SYSTEM
  </h3>
        </span>
          </div>
          <div className='bg-[#F7F7F7]'>
          <div className='bg-[#F7F7F7]'>
         <div className="text-center p-5 text-gray-700 font-semibold leading-relaxed max-w-2xl mx-auto pt-9">
  <p className="pt-10">
    <span className="">AVES</span> is a Security Governance System for the online management of security operations of events, concerts, conferences and exhibitions. It offers disciplined documentation, real-time reporting, and monitoring of compliance—facilitating secure coordination of security, crowd management, and recording incidents without any extra hardware.
  </p>
  <p className="mb-4">
 

  </p>
</div></div>
            <div className="bg-[#F7F7F7] p-6 md:p-12">
         {/* Header Section */}
         <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
         WHY SHOULD EVENT ORGANIZERS EMPLOY AVES SECURITY GOVERNANCE SYSTEM?
         </h2>
         </div>
   
         {/* Content Section */}
         <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Andriod}
               alt="Security App"
               className="w-full max-w-lg rounded-3xl shadow-xl"
             />
           </div>
   
           {/* Features Section */}
           
           <div className="space-y-6">
             {features.map((feature, index) => (
               <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                      <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
       <Check className='w-4 h-4 relative bottom-[8px] right-[8px] text-center' />
   </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>
           <div></div>
         </div>
          <div className="bg-[#F7F7F7] p-6 md:p-12">
                <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
                MAJOR CHARACTERISTICS OF OUR AVES EVENT SECURITY SOFTWARE SYSTEM
                  </h2>
                  {/* Content Section */}
                  <div className="grid md:grid-cols-2 gap-8 items-center">
                    {/* Features Section */}
                    <div className="space-y-6">
                      {feature.map((feature, index) => (
                        <div key={index} className=" p-4 rounded-lg flex items-start gap-3">
                                <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
                <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
            </span>
                          <div>
                            <h3 className="font-bold text-lg">{feature.title}</h3>
                            <p className="text-gray-600 text-sm">{feature.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>   
                    {/* Image Section */}
                    <div className="flex justify-center">
                      <img
                        src={Ios}
                        alt="Security App"
                        className="w-full max-w-md rounded-lg shadow-lg"
                      />
                    </div>
                  </div>
                </div>
                <div></div>
         
       </div>
       
       <div className="bg-white p-6 md:p-12">
        <h2 className='text-2xl md:text-3xl font-bold text-black text-center mb-8'>
        KEY FEATURES OF OUR EVENT SECURITY SYSTEM

        </h2>
       <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Androids}
               alt="Security App"
               className="w-full max-w-xl rounded-3xl shadow-xl"
             />
           </div>
   
           {/* Features Section */}
           <div className="space-y-6">
             {fea.map((feature, index) => (
               <div key={index} className=" p-4 rounded-lg flex items-start gap-3">
                      <span className="mt-2  bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
       <Check className='w-4 h-4 relative bottom-[8px] right-[8px] text-center' />
   </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>
           </div>
       </div>
       </div>
       <div>
        </div>
        <div className="relative w-full">
          {/* Background Image */}
          <img
            src={HospitaltyBanner}
            alt="Hospitality Security"
            className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
          />
    
          {/* Text Overlay */}
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
            <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
              Get Started Today
            </h2>
            <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
            Experience the best mobile app for security with Aves – a state of the art Security  Governance System for your corporate world.
            </p>
    
            {/* CTA Button */}
            <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
              Contact Us →
            </button>
          </div>
        </div>
        <div className="px-6 lg:px-20 py-10 bg-gray-100">
          {/* Title Section */}
          <div className="text-center mb-10">
            <h2 className="text-2xl font-bold uppercase text-gray-800">
              FOR WHOM WE ARE BUILDING
            </h2>
            <h3 className="text-xl font-semibold text-gray-700">
              OUR EVENT SECURITY  SOFTWARE SYSTEM
            </h3>
          </div>
          
             {/* Icon Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">CONCERT AND MUSIC FESTIVAL</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">CORPORATE CONFERENCE</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">TRADE SHOWS</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">SPORTING EVENT STADIUM</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">EXHIBITIONS PUBIC GATHERINGS</p>
  </div>
</div>
 </div>
  <div className='bg-white '>
           <div className="mt-16 text-center px-10 py-10 rounded-xl">
             <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
           
             <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {/* Step 1 */}
            <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
              <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
                1
              </div>
              <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
              <p className="mt-2 text-gray-600 text-sm">
              Join to organize event security operations online.


              </p>
            </div>
        
            {/* Step 2 */}
            <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
              <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
                2
              </div>
              <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
              <p className="mt-2 text-gray-600 text-sm">
              Sustain structured governance for visitor safety, security patrol operations, and emergency action plans.



              </p>
            </div>
        
            {/* Step 3 */}
            <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
              <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
                3
              </div>
              <h3 className="mt-4 text-lg font-bold text-gray-700">Start Securing</h3>
              <p className="mt-2 text-gray-600 text-sm">
                Smartly monitor, manage, and optimize—because that's what Security Operations is all about.
              </p>
            </div>
          </div>
           </div>
           </div>
     
        </section>
      )
    }
    const features = [
      {
        title: "COMPREHENSIVE SECURITY SOLUTIONS FOR EVENT PROTECTION",
        description: "AVES offers a complete Event Security Software solution aimed at optimizing and improving your event’s security processes."
      },
      {
        title: "REAL-TIME SURVEILLANCE",
        description: "Maintain an edge with immediate notifications and real-time information readily available, facilitating proactive security oversight at events of all sizes."
      },
      {
        title: "CUSTOMIZABLE AND SCALABLE",
        description: "The Event Security Management App is adaptable to fulfill the specific requirements of your organization, offering a flexible and scalable security structure."
      },
      {
        title: "USER-FRIENDLY AND INTUITIVE",
        description: "Our Event Security Guard Management Platform is crafted for easy integration, minimizing implementation time through a user-friendly interface."
      },
      {
        title: "DATA-DRIVEN SECURITY STRATEGIES",
        description: "Utilize actionable insights and analytics to enhance security protocols throughout your event venue."
      }
    ];
    
    const feature = [
      {
        title: "VISITOR & VENDOR ACCESS MANAGEMENT",
        description: "Keep digital records of all event guests, vendors, and VIPs.Monitor pre-approved access passes and security clearance approvals.",
      },
      {
        title: " INCIDENT REPORTING & CROWD SAFETY MANAGEMENT",
        description: "Digitally record suspicious incidents, security compromises, and guest complaints.Keep systematically an emergency incident record, lost and found articles, or disturbances."
      },
      {
        title: "SECURITY STAFF COORDINATION & SHIFT MANAGEMENT",
        description: "Schedule event security staff tasks, patrol routes, and watch duties.Keep computerized records of security shifts and duty lists."
      },
      {
        title: "EMERGENCY RESPONSE & SAFETY ALERTS",
        description: "Schedule evacuation protocols, medical emergencies, and crisis response protocols.Keep venue license records, security clearances, and hazard reports.Monitor compliance with domestic security laws and safety protocols."
      }
    ]

    const fea = [
      {
        title: "GUEST & VIP SECURITY MANAGEMENT",
        description: "Monitor guest arrivals digitally, VIP access, and crowd access monitoring.Implemented systematic security surveillance of restricted areas and backstage access.",
      },
      {
        title: "SECURITY INCIDENT REPORTING & CASE MANAGEMENT",
        description: "Monitor suspicious incidents, unauthorized access, and lost & found incident reports.Provide properly organized reports of security incidents, theft, or disruptions"
      },
      {
        title: "SECURITY STAFF DEPLOYMENT & SHIFT MANAGEMENT",
        description: "Monitor security officer ground surveillance patrol.Proper rostering of event security staff management."
      },
      {
        title: "EQUIPMENT & ASSET SECURITY TRACKING",
        description: "Monitor the issue and return of security radios and event keys.Monitor security pass records for vendors, contractors, and media personnel."
      },
      {
        title: "EMERGENCY RESPONSE & EVACUATION COORDINATION",
        description: "Keep systematic records of emergency response drills and crowd evacuation procedures.Organize security officers, medical professionals, and event promoters immediately."
      }
    ]
    
    const Step = ({ number, title, description }) => {
      return (
        <div className="flex  items-center gap-4">
          <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
            {number}
          </div>
          <div>
            <h3 className="font-bold text-lg">{title}</h3>
            <p className="text-gray-600 text-sm">{description}</p>
          </div>
          
        </div>
  )
}

export default EventSecurity