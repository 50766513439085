import React from 'react'

import Hero from './Hero'
import Features from './Features'
import Testimonial from './Testimonial'
import CallToAction from './CallToAction'
function Homes() {
  return (
    <div>
    <Hero />
      <Features />
      <Testimonial />
      <CallToAction />
    
    </div>
  )
}

export default Homes