import illustrationIntro from '../assets/SecurityMale.png';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section id="hero">
    <div className="bg-[#000D16]">
      <div className="flex flex-col lg:flex-row-reverse items-center lg:items-center justify-center lg:justify-between lg:gap-10 px-5 lg:px-20">
        {/* Image Section */}
        <div className="flex justify-end relative left-20 lg:justify-end lg:w-1/2 xl:justify-end">
          <img
            src={illustrationIntro}
            alt="Security Illustration"
            className="max-w-full  sm:lg:max-h-[650px]  md:lg:max-h-[650px] lg:max-h-[650px] object-contain"
          />
        </div>

        {/* Text Section */}
        <div className="text-center lg:text-left lg:w-1/2 pb-10">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold leading-tight pt-10">
            AVES SECURITY <br />GOVERNANCE SYSTEM
          </h1>
          <p className="text-slate-300 text-lg mt-4">
          Empowering Security Operations for a Safer Tomorrow
          </p>
          <p className="text-slate-300 text-base mt-4 font-serif">      
          AVES Security Management brings everything you need into one app. Whether it's managing teams, tracking incidents, ensuring compliance, or monitoring performance, we cover all aspects of security operations in a single, easy-to-use platform.
          </p>
          <button className='bg-[#FABC3F] w-[132px] h-11 rounded-2xl mt-4 text-base font-bold text-gray-900  hover:scale-105 transition duration-300 ease-in-out'>
              <Link
                          to="/service"
                          className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
                        >
                            Learn More
                        </Link>
          
          </button>

        </div>
      </div>
    </div>
  </section>
  );
};

export default Hero;
