import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Mobile from '../assets/images/iPhone .png'
import Andriod from '../assets/Doc.png'
import Ios from '../assets/Healthcaredoctor.png'
import HowIt from './HowIt';
import { Check } from 'lucide-react';
import HealthMale from '../assets/HealthCallerMale.png'
import SecurityMale from '../assets/HealthcareBanner.png'
import HospitaltyBanner from '../assets/HospitallityCheck.png'
import Restarant from '../assets/hospital.png'
import Event from '../assets/Clints.png'
import Resort from '../assets/Dignosis.png'
import ConventionCenter from '../assets/Pharmasis.png'
import Tour from '../assets/Medical report.png'

function HealthCareSecurity() {
  return (
    <section>
       <div className='bg-white'>
         <div>
           <img src={SecurityMale} alt="Security App" className="w-screen" />
           <span className='absolute top-60 p-20 text-xl sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl pb-5 font-bold text-white'>
        <h1 className="">
        HEALTHCARE
  </h1>
  <h3 className='font-light pt-2'>
  SECURITY MANAGEMENT SYSTEM
  </h3>
        </span>
         </div>
         <div className='bg-[#F7F7F7]'>
         <div className='text-center p-5 pt-10 text-gray-500 font-semibold'>
         <div className=''>
         AVES serves as a Security Governance System that provides systematic oversight and compliance
         </div>
         <div>
         operational control for hospitals, clinics, and healthcare organizations.  
         </div>
         <div>
         It aids in managing security documentation, visitor access, incident reporting

         </div>
         <div>
         Emergency readiness through a centralized digital interface.
           </div>
           </div>
         <div className="bg-[#F7F7F7] p-6 md:p-12">
         {/* Header Section */}
         <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
           WHY SHOULD SOMEONE DOWNLOAD OUR HEALTHCARE SECURITY GOVERNANCE SYSTEM?
         </h2>
         </div>
   
         {/* Content Section */}
         <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Andriod}
               alt="Security App"
               className="w-full max-w-[388px] rounded-3xl shadow-xl"
             />
           </div>
   
           {/* Features Section */}
           <div className="space-y-6">
             {features.map((feature, index) => (
               <div key={index} className="bg-[#F7F7F7] p-4 rounded-lg flex items-start gap-3">
                     <span className="mt-4 bg-gray-600 text-white w-6 h-6 text-sm font-black p-[10px] relative  top-[-10px] rounded-full">
                 <Check className='w-4 h-4 relative bottom-[6px] right-[5px] text-center' />
             </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm">{feature.description}</p>
                 </div>
               </div>
             ))}
           </div>
         </div>
       </div>
       <div className="bg-white p-6 md:p-12">
       <h2 className="text-2xl md:text-3xl font-bold text-black text-center mb-8">
MAJOR CHARACTERISTICS OF  AVES OUR HEALTHCARE SECURITY SOFTWARE SYSTEM
         </h2>
         {/* Content Section */}
         <div className="grid md:grid-cols-2 gap-8 items-center">
           {/* Features Section */}
           <div className="space-y-6">
             {feature.map((feature, index) => (
               <div key={index} className="bg-white p-4 rounded-lg flex items-start gap-3">
                       <span className="mt-1 bg-gray-600 text-white w-6 h-6 text-sm font-black p-3 rounded-full">
       <Check className='w-4 h-4 relative bottom-[6px] right-[8px] text-center' />
   </span>
                 <div>
                   <h3 className="font-bold text-lg">{feature.title}</h3>
                   <p className="text-gray-600 text-sm pb-1">{feature.description}</p>
                   <p className="text-gray-600 text-sm">{feature.descriptions}</p>
                 </div>
               </div>
             ))}
           </div>
   
           {/* Image Section */}
           <div className="flex justify-center">
             <img
               src={Ios}
               alt="Security App"
               className="w-full max-w-md rounded-lg shadow-lg"
             />
           </div>
         </div>
       </div>
       </div>
       <div>
       </div>
       <div className="relative w-full">
         {/* Background Image */}
         <img
           src={HospitaltyBanner}
           alt="Hospitality Security"
           className="w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] object-cover"
         />
   
         {/* Text Overlay */}
         <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
           <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
             Get Started Today
           </h2>
           <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-xl font-medium space-y-2 mt-2 max-w-2xl px-0">
           Experience the best mobile app for security with Aves – a state of the art Security  Governance System for your Healthcare Security
           </p>
   
           {/* CTA Button */}
           <button className="mt-4 bg-white text-black px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base md:text-lg hover:bg-gray-200 transition">
             Contact Us →
           </button>
         </div>
       </div>
       <div className="px-6 lg:px-20 py-10 bg-gray-100">
         {/* Title Section */}
         <div className="text-center mb-10">
           <h2 className="text-2xl font-bold uppercase text-gray-800">
             FOR WHOM WE ARE BUILDING
           </h2>
           <h3 className="text-2xl font-bold text-gray-800">
             OUR HEALTHCARE SECURITY SOFTWARE SYSTEM 
           </h3>
         </div>
         
          {/* Icon Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 text-center">
  {/* First Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-400">
    <img   className='text-white' src={Restarant} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Hospitals</p>
  </div>

  {/* Second Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-blue-400">
    <img   className='text-white' src={Event} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Clinics</p>
  </div>

  {/* Third Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-purple-400">
    <img   className='text-white' src={Resort} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Diagnostic Centers</p>
  </div>

  {/* Fourth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-cyan-400">
    <img   className='text-white' src={ConventionCenter} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Pharmaceutical Facilities</p>
  </div>

  {/* Fifth Box */}
  <div className="bg-white p-6 rounded-lg shadow-2xl flex flex-col items-center">
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-400">
    <img   className='text-white' src={Tour} />
    </div>
    <p className="mt-4 font-semibold text-gray-700">Medical Research Labs</p>
  </div>
</div>
   {/* How It Works Section */}
       </div>
       <div className='bg-white '>
   <div className="mt-16 text-center px-10 py-10 rounded-xl">
     <h2 className="text-2xl font-bold text-gray-800">How It Works</h2>
   
     <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
    {/* Step 1 */}
    <div className="bg-white p-6 rounded-lg flex flex-col items-center " >
      <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-red-400 text-white text-lg font-black">
        1
      </div>
      <h3 className="mt-4 text-lg font-bold text-gray-700">Sign Up With Aves</h3>
      <p className="mt-2 text-gray-600 text-sm">
      The healthcare facility can sign up for digital security management through the registration process. 
      </p>
    </div>

    {/* Step 2 */}
    <div className="bg-white p-6 rounded-lg  flex flex-col items-center " >
      <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-blue-400 text-white text-lg font-black">
        2
      </div>
      <h3 className="mt-4 text-lg font-bold text-gray-700">Set Up Your Profile</h3>
      <p className="mt-2 text-gray-600 text-sm">
      Users must configure procedures for incident reporting as well as access controls and maintain records of facility emergency preparedness.
      </p>
    </div>

    {/* Step 3 */}
    <div className="bg-white p-6 rounded-lg  flex flex-col items-center ">
      <div className="w-10 h-10 flex items-center justify-center rounded-r-sm bg-purple-400 text-white text-lg font-black">
        3
      </div>
      <h3 className="mt-4 text-lg font-bold text-gray-700">Start Securing</h3>
      <p className="mt-2 text-gray-600 text-sm">
      The system enables digital document maintenance along with incident response automation with regulatory adherence. 
      </p>
    </div>
  </div>
   </div>
   </div>
   
       </section>
     )
   }
   const features = [
     {
       title: "COMPREHENSIVE SECURITY SOLUTIOND FOR HEALTHCARE PROTECTION",
       description: "AVES delivers a thorough security solution specifically designed for the healthcare sector, aimed at optimizing and refining your organization's security processes."
     },
     {
       title: "REAL-TIME SURVEILLANCE",
       description: "Gain a competitive advantage with prompt notifications and real-time information, which support proactive security management in healthcare settings."
     },
     {
       title: "CUSTOMIZABLE AND SCALABLE",
       description: "The Security Management Platform for Healthcare Facilities is flexible and can be tailored to meet the distinct needs of your organization, providing a scalable security infrastructure."
     },
     {
       title: "USER-FRIENDLY AND INTUITIVE",
       description: "Our Healthcare Security Guards Management Platform is designed for straightforward integration, significantly reducing implementation time through its user-friendly design."
     },
     {
       title: "DATA-DRIVEN SECURITY STRATEGIES",
       description: "Employ actionable insights and analytics to improve security protocols throughout your healthcare environment."
     }
   ];
   
   const feature = [
     {
       title: "PATIENT & INCIDENT REPORTING",
       description: "Ensure that security incidents, unauthorized access, and patient emergencies are logged in real-time",
       descriptions: "Maintain organized documentation to support incident resolution and compliance checks."
     },
     {
       title: "ACCESS GOVERNANCE AND CONTROL OF RESTRICTED AREAS",
       description: "Manage employee entry to critical zones within the hospital, including intensive care units, surgical departments, and pharmaceutical storage areas.",
       descriptions:"Supervise visitor access by implementing defined authorization procedures to maintain healthcare security protocols."
     },
     {
       title: "MEDICAL SUPPLY & EQUIPMENT SECURITY",
       description: "Record medical supply deliveries, conduct checks on pharmaceutical storage, and document reports of lost inventory in a digital format. ",
       descriptions:"Keep secure access logs for medical storage areas and controlled substances."
     },
     {
       title: "VISITOR & CONTRACTOR MANAGEMENT",
       description: "Optimize the check-in process for patient visitors and contractors by leveraging organized digital records",
       descriptions:"Keep accurate logs of entries and exits to ensure compliance with security protocols in sensitive healthcare environments."
     },
     {
       title: "EMERGENCY RESPONSE & DRILL COORDINATION",
       description: "Keep thorough records of emergency preparedness, including training for personnel and established response plans.",
       descriptions:"Ensure that emergency drills are routinely organized and implemented throughout the hospital to address different types of emergencies."
     }
   ]
   
   const Step = ({ number, title, description }) => {
     return (
       <div className="flex  items-center gap-4">
         <div className="bg-black text-white w-12 h-12 flex items-center justify-center text-xl font-bold rounded-full">
           {number}
         </div>
         <div>
           <h3 className="font-bold text-lg">{title}</h3>
           <p className="text-gray-600 text-sm">{description}</p>
         </div>
         
       </div>
  )
}

export default HealthCareSecurity